import React from 'react'

export default function Info (): JSX.Element {
  return (
    <svg width="46" height="43" viewBox="0 0 46 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0.998047H46C46 0.998047 46 10.4961 24.3529 28.4368C2.70588 46.3775 0 42.6838 0 42.6838V7.99805C0 4.13205 3.13401 0.998047 7 0.998047Z"
        fill="#A0C813"/>
      <path
        d="M14.46 25.998V13.938L17.44 13.798V25.998H14.46ZM17.6 11.278C17.6 12.1447 17.0533 12.578 15.96 12.578C15.4 12.578 14.98 12.478 14.7 12.278C14.4333 12.078 14.3 11.7447 14.3 11.278C14.3 10.398 14.8533 9.95805 15.96 9.95805C17.0533 9.95805 17.6 10.398 17.6 11.278Z"
        fill="white"/>
    </svg>
  )
}

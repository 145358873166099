import React, { useEffect, useState } from 'react'
import Revision from 'models/Revision'
import './Revisions.scss'
import Tokens from '../token/Tokens'
import SmilySheet from 'assets/icons/SmilySheet'
import PropTypes from 'prop-types'
import ChevronDown from 'assets/icons/ChevronDown'

interface RevisionsProps {
  revisions: Revision[]
  emptyText?: string
  padding?: string
}

Revisions.propTypes = {
  revisions: PropTypes.arrayOf(PropTypes.instanceOf(Revision)).isRequired,
  padding: PropTypes.string,
  emptyText: PropTypes.string
}

const defaultProps = {
  padding: '2px 12px',
  emptyText: null
}

export default function Revisions (propsIn: RevisionsProps): JSX.Element {
  const props = { ...defaultProps, ...propsIn }
  const [currentRevision, setCurrentRevision] = useState<Revision | null>(props.emptyText === null ? props.revisions[0] : null)

  useEffect(() => {
    setCurrentRevision(props.revisions[0])
  }, [props.revisions])

  return (
    <div className="revisions">
      {props.emptyText !== null && (
        <span onClick={() => setCurrentRevision(null)} className="label label-revision"
              style={{ padding: props.padding }}>
          Abgabe {props.revisions.length + 1}
        </span>
      )}
      {props.revisions.map((revision, key) => {
        return (
          <span key={revision.id} className="label label-revision" onClick={() => setCurrentRevision(revision)}
                style={{ padding: props.padding }}>
            {revision.id === props.revisions[0].id && props.emptyText === null ? 'Aktuelle' : ''} Abgabe {props.revisions.length - key}
          </span>
        )
      })}
      <div className="current-revision">
        {props.emptyText !== null && currentRevision === null && (
          <div className="submitted">
            <SmilySheet/>
            <div>
              {props.emptyText}
              <div className="down">
                <ChevronDown color="#D8D8D8"/>
              </div>
            </div>
          </div>
        )}
        {currentRevision !== null && (
          <Tokens tokens={currentRevision.tokens} corrections={currentRevision.corrections}/>
        )}
      </div>
    </div>
  )
}

import React from 'react'

export default function Check (): JSX.Element {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_645)">
        <path
          d="M14.4504 10.7179V15.4639C14.4504 15.9246 14.2655 16.3665 13.9364 16.6924C13.6073 17.0184 13.1608 17.2018 12.6951 17.2024H2.55928C2.09331 17.2018 1.6466 17.0185 1.31711 16.6926C0.987621 16.3667 0.802245 15.9248 0.801636 15.4639V2.53357C0.802246 2.07289 0.987696 1.63128 1.31725 1.30575C1.64681 0.980211 2.09352 0.797363 2.55928 0.797363H12.6951C12.9256 0.797363 13.1538 0.842272 13.3668 0.929524C13.5798 1.01678 13.7733 1.14466 13.9363 1.30589C14.0993 1.46711 14.2286 1.65851 14.3168 1.86915C14.405 2.0798 14.4504 2.30557 14.4504 2.53357"
          stroke="#5F676B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.71777 8.90195L8.16625 12.3106L18.1938 2.39233" stroke="#5F676B" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1549_645">
          <rect width="19" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

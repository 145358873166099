import * as React from 'react'

import Correction from 'models/Correction'
import { EditorContextType } from '../@types/editor'
import { useEffect, useState } from 'react'
import CorrectionType from 'models/CorrectionType'
import TokenService from 'services/TokenService'
import Token from 'models/Token'
import CorrectionTypes from 'enums/CorrectionTypes'

export const EditorContext = React.createContext<EditorContextType | null>(null)

interface CorrectionContextType {
  children?: JSX.Element
  content: string
}

export default function EditorProvider (props: CorrectionContextType): JSX.Element {
  const [corrections, setCorrections] = useState<Correction[]>([])
  const [hiddenCorrections, setHiddenCorrections] = useState<Correction[]>([])
  const [hiddenSuggestionIndexes, setHiddenSuggestionIndexes] = useState<number[]>([])

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [suggestionsEnabled, setSuggestionsEnabled] = useState<string>('')
  const [editCorrection, setEditCorrection] = useState<boolean>(false)
  const [correctionValue, setCorrectionValue] = useState<string>('')
  let tokenService = new TokenService(props.content)
  const [tokens, setTokens] = useState<Token[]>(tokenService.getTokens())

  const [selectedTokens, setSelectedTokens] = useState<Token[]>([])
  const [correction, setCorrection] = useState<Correction | undefined>(undefined)

  useEffect(() => {
    deleteSuggestions()
    setCorrection(undefined)
    setHiddenCorrections([])
    setHiddenSuggestionIndexes([])
    setCorrections([])
    tokenService = new TokenService(props.content)
    setTokens(tokenService.getTokens())
    setSuggestionsEnabled('')
  }, [props.content])

  useEffect(() => {
    if (correction !== undefined) {
      corrections
        .sort((a, b) => a.tokens[0].id > b.tokens[0].id ? 1 : -1)
        .forEach(function (value, index) {
          if (value.id === correction.id) {
            setCurrentIndex(index)
          }
        })
    }
  }, [correction])

  const handleMouseUp = function (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    const selection = window.getSelection()
    if (selection != null) {
      const range = selection.getRangeAt(0)
      const fragment: DocumentFragment = range.cloneContents()
      const ids: Array<string | undefined | null> = []
      if (fragment.children.length > 0) {
        for (const item of Array.from(fragment.children)) {
          if (item.getAttribute('data-correction') !== null) {
            for (const token of Array.from(item.children)) {
              ids.push(token.getAttribute('data-id'))
            }
          } else if (item.getAttribute('data-id') !== null) {
            ids.push(item.getAttribute('data-id'))
          }
        }
      } else {
        ids.push(selection.anchorNode?.parentElement?.getAttribute('data-id'))
      }

      const filteredIds = ids
        .filter((id) => id !== null && id !== undefined)
        .map((id) => Number(id))

      if (filteredIds.length > 0) {
        setCorrection(undefined)
        setSelectedTokens(getTokensBySelection(filteredIds))
      }
    }
  }

  const createCorrection = function (correctionType: CorrectionType): void {
    if (selectedTokens.length > 0) {
      const cors = corrections.filter((cor) => {
        return selectedTokens.find(t => cor.tokens.find(token => token.id === t.id)) === undefined
      })

      const parent: Correction | undefined = corrections.find((c) => {
        let matches = 0
        c.tokens.forEach((token) => {
          if (selectedTokens.find(t => t.id === token.id) !== undefined) {
            matches = matches + 1
          }
        })

        return !c.suggestion && matches === selectedTokens.length && c.tokens.length > matches
      })

      const cor = new Correction(correctionType, selectedTokens)

      if (parent !== undefined && cor.root) {
        cor.root = false
        parent.children.push(cor)
      } else {
        setCorrections(cors.concat(cor))
      }

      setCorrection(cor)
      setSelectedTokens([])

      const selection = window.getSelection()
      if (selection != null) {
        selection.removeAllRanges()
      }
    }
  }

  const deleteCorrection = function (cor: Correction): void {
    if (correction != null) {
      setCorrection(undefined)
    }
    corrections.forEach((c) => {
      if (c.children.length > 0) {
        c.children = c.children.filter((child) => child.id !== cor.id)
      }
    })
    setCorrections(corrections.filter((correction) => correction.id !== cor.id))
    if (cor.suggestionIndex !== undefined) {
      setHiddenSuggestionIndexes(hiddenSuggestionIndexes.concat(cor.suggestionIndex))
    }
  }

  const updateCorrectionType = function (name: string): void {
    if (correction !== undefined) {
      Object.values(CorrectionTypes).forEach((value) => {
        if (value.name === name) {
          updateCorrection(correction, { type: value })
        }
      })
    }
  }

  const updateCorrectedValue = function (value: string | null): void {
    if (value === null) {
      return
    }
    if (correction !== undefined) {
      updateCorrection(correction, { correction: value, activeSuggestion: undefined })
      setEditCorrection(false)
      setCorrectionValue('')
    }
  }
  const removeCorrectedValue = function (): void {
    if (correction !== undefined) {
      updateCorrection(correction, { correction: '' })
      setCorrectionValue('')
    }
  }

  const updateComment = function (value: string): void {
    if (correction !== undefined) {
      updateCorrection(correction, { comment: value })
    }
  }
  const strikeOut = function (): void {
    if (correction !== undefined) {
      updateCorrection(correction, { strikedOut: !correction.strikedOut })
    }
  }

  const nextCorrection = function (): void {
    if (currentIndex < corrections.length - 1) {
      setCurrentIndex(currentIndex + 1)
      setCorrection(corrections[currentIndex + 1])
    }
  }

  const prevCorrection = function (): void {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
      setCorrection(corrections[currentIndex - 1])
    }
  }

  const acceptSuggestion = function (c: Correction): void {
    updateCorrection(c, { accepted: true, suggestion: false })
    if (c.suggestionIndex !== undefined) {
      setHiddenSuggestionIndexes(hiddenSuggestionIndexes.concat(c.suggestionIndex))
    }
  }

  const acceptAllSuggestions = function (): void {
    if (correction !== undefined) {
      setCorrection({ ...correction, accepted: true, suggestion: false })
    }
    setCorrections(corrections.map(cor => {
      return { ...cor, accepted: true, suggestion: false }
    }))

    const indexes: number[] = []

    corrections.forEach((cor) => {
      if (cor.suggestionIndex !== undefined) {
        indexes.push(cor.suggestionIndex)
      }
    })
    setHiddenSuggestionIndexes(indexes)
  }

  const deleteSuggestions = function (): void {
    if (suggestionsEnabled !== 'errors') {
      setCorrections(hiddenCorrections.concat(corrections.filter((cor) => !cor.suggestion)))
    } else {
      setCorrections(corrections.filter((cor) => !cor.suggestion || cor.accepted))
    }
  }

  const updateCorrection = function (c: Correction, updateValues: any): void {
    const cor = { ...c, ...updateValues }
    setCorrection(cor)
    setCorrections(corrections.map(c => {
      if (cor.id === c.id) {
        return cor
      } else {
        if (c.children.length > 0) {
          c.children = c.children.map(child => {
            if (cor.id === child.id) {
              return cor
            } else {
              return child
            }
          })

          return c
        } else {
          return c
        }
      }
    }))
  }

  const getTokensBySelection = function (ids: number[]): Token[] {
    const arr: Token[] = []

    tokens.forEach(token => {
      if (ids.includes(token.id)) {
        arr.push(token)
      }
    })

    return arr
  }

  const setActiveSuggestion = function (index: number): void {
    if (correction !== undefined) {
      updateCorrection(correction, { correction: correction.suggestions[index], activeSuggestion: index })
    }
  }

  return <EditorContext.Provider value={{
    corrections,
    hiddenCorrections,
    hiddenSuggestionIndexes,
    correction,
    correctionValue,
    editCorrection,
    tokens,
    selectedTokens,
    currentIndex,
    suggestionsEnabled,
    prevCorrection,
    nextCorrection,
    createCorrection,
    setCorrection,
    deleteCorrection,
    handleMouseUp,
    strikeOut,
    setCorrectionValue,
    setEditCorrection,
    updateComment,
    updateCorrectionType,
    updateCorrectedValue,
    deleteSuggestions,
    removeCorrectedValue,
    setTokens,
    setCorrections,
    acceptSuggestion,
    setSuggestionsEnabled,
    acceptAllSuggestions,
    setActiveSuggestion,
    setHiddenCorrections,
    setHiddenSuggestionIndexes,
    setSelectedTokens
  }}>{props.children}</EditorContext.Provider>
}

import React from 'react'

export default function Download (): JSX.Element {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_429_2307)">
        <path d="M11 0.708008V14.6438" stroke="#B7B7B7" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M16.0894 9.49512L10.9998 14.6438L5.91016 9.49512" stroke="#B7B7B7" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M21.3024 14.0176V16.4772C21.3024 17.491 20.9043 18.4633 20.1957 19.1801C19.487 19.897 18.5259 20.2997 17.5237 20.2997H4.47891C3.47673 20.2997 2.5156 19.897 1.80695 19.1801C1.09831 18.4633 0.700195 17.491 0.700195 16.4772V14.0232"
          stroke="#B7B7B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_429_2307">
          <rect width="22" height="21" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import CorrectionType from 'models/CorrectionType'
import Skip from 'assets/icons/Skip'
import React from 'react'
import Expression from 'assets/icons/Expression'
import Grammar from 'assets/icons/Grammar'
import Punctuation from 'assets/icons/Punctuation'
import Repeat from 'assets/icons/Repeat'
import Spelling from 'assets/icons/Spelling'
import Syntax from 'assets/icons/Syntax'
import Note from 'assets/icons/Note'

const CorrectionTypes = {
  SKIP: new CorrectionType('Auslassung', <Skip />),
  EXPRESSION: new CorrectionType('Ausdruck', <Expression />),
  GRAMMAR: new CorrectionType('Grammatik', <Grammar />),
  PUNCTUATION: new CorrectionType('Zeichensetzung', <Punctuation />),
  REPEAT: new CorrectionType('Wiederholung', <Repeat />),
  SPELLING: new CorrectionType('Rechtschreibung', <Spelling />),
  SYNTAX: new CorrectionType('Satzbau', <Syntax />),
  NOTE: new CorrectionType('Hinweis', <Note />)
}

export default CorrectionTypes

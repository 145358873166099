import React from 'react'
import Inka from 'assets/icons/Inka'
import './Main.scss'
import { Link } from 'react-router-dom'

export default function Main (): JSX.Element {
  return (
    <div className="container main">
      <div className="row">
        <div className="col-md-12">
          <div className="logo">
            <Inka/>
          </div>
          <div className="slogan">
            INTELLIGENTER KORREKTURASSISTENT
          </div>
          <div className="buttons">
            <Link to={'/teacher'} className="btn btn-outline-transparent">LEHRKRAFT DEMO</Link>
            <Link to={'/student'} className="btn btn-outline-transparent">KTL DEMO</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import './Editor.scss'
import PropTypes from 'prop-types'
import CorrectionTypes from 'enums/CorrectionTypes'
import Trash from 'assets/icons/Trash'
import Prev from 'assets/icons/Prev'
import Next from 'assets/icons/Next'
import Tokens from '../token/Tokens'
import Help from '../Help'
import guide1 from 'assets/images/guide_2.png'
import guide2 from 'assets/images/guide_1.png'
import Close from 'assets/icons/Close'
import Save from 'assets/icons/Save'
import { EditorContext } from 'context/editorContext'
import { EditorContextType } from '../../@types/editor'
import Accept from 'assets/icons/Accept'

interface EditorProps {
  name: string
  showCorrectionBox?: boolean
}

Editor.propTypes = {
  name: PropTypes.string.isRequired,
  showCorrectionBox: PropTypes.bool
}

const defaultProps = {
  showCorrectionBox: false
}

export default function Editor (propsIn: EditorProps): JSX.Element {
  const props = { ...defaultProps, ...propsIn }
  const buttonRef = useRef<HTMLButtonElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [contentEditable, setContentEditable] = useState<boolean>(false)

  const {
    corrections,
    correction,
    currentIndex,
    editCorrection,
    correctionValue,
    tokens,
    selectedTokens,
    suggestionsEnabled,
    prevCorrection,
    nextCorrection,
    createCorrection,
    deleteCorrection,
    handleMouseUp,
    strikeOut,
    setCorrection,
    updateComment,
    updateCorrectionType,
    updateCorrectedValue,
    removeCorrectedValue,
    setCorrectionValue,
    setEditCorrection,
    acceptSuggestion,
    acceptAllSuggestions,
    setActiveSuggestion
  } = React.useContext(EditorContext) as EditorContextType

  useEffect(() => {
    if (editCorrection && inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [editCorrection])

  const editButton = function (): void {
    if (buttonRef.current !== null && !contentEditable) {
      setContentEditable(true)
      buttonRef.current.focus()
      window.getSelection()?.selectAllChildren(buttonRef.current)
      window.getSelection()?.collapseToEnd()
    }
  }

  const blurButton = function (value: string): void {
    updateCorrectedValue(value)
    setContentEditable(false)
  }

  const addCorrectedValue = function (): void {
    if (correction === undefined) {
      return
    }

    if (correction.correction.length > 0 && correction.activeSuggestion === undefined) {
      setContentEditable(true)
      if (buttonRef.current !== null) {
        buttonRef.current.focus()
        window.getSelection()?.selectAllChildren(buttonRef.current)
        window.getSelection()?.collapseToEnd()
      }
    } else {
      setEditCorrection(true)
    }
  }

  return (
    <div className="row editor">
      <div className={props.showCorrectionBox ? 'col-md-8' : 'col-md-12'}>
        <div className="textarea">
          <div className="toolbox">
            {suggestionsEnabled === 'errors' && corrections.filter(cor => cor.suggestion).length > 0 &&
              <span className="float-right" onClick={() => acceptAllSuggestions()}><Accept /> Alle Fehler übernehmen</span>
            }
          </div>
          <div className="content-box" onMouseUpCapture={handleMouseUp}>
            <Tokens tokens={tokens} corrections={corrections} stateChanger={setCorrection} editor={true} selectedTokens={selectedTokens} />
          </div>
        </div>
      </div>
      {props.showCorrectionBox &&
        <div className="col-md-4">
          <div className="correction-box">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active">
                  Fehler
                  <Help maxWidth={541} positions={['bottom']} >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="guide">
                          <img src={guide1} alt="" />
                          <hr />
                          <span>
                            Dies ist Ihr Fehlerinspektor.
                            Sobald Sie ein Wort oder eine Wortgruppe zur Korrektur markiert haben oder auf einen von INKA vorgeschlagenen Fehler klicken, können Sie hier folgendes tun:

                          </span>
                        </div>
                        <div className="guide">
                          <img src={guide2} alt="" />
                          <hr />
                          <span>
                            <ul>
                              <li>Fehlerkategorie auswählen oder ändern</li>
                              <li> vorgeschlagene Verbesserung ansehen, ändern oder einen Verbesserungsvorschlag selbst eingeben</li>
                              <li> überflüssige oder falsch platzierte Wörter im Text durchstreichen</li>
                              <li> einen spezifischen Kommentar zum korrigierten Fehler verfassen</li>
                              <li> Fehler löschen oder Korrekturvorschläge von INKA akzeptieren</li>
                            </ul>

                          </span>
                        </div>
                      </div>
                    </div>
                  </Help>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active">
                {correction === undefined &&
                  <ul>
                    {Object.entries(CorrectionTypes).map(([key, value]) => {
                      return (
                        <li key={key}>
                          <button type="button" className="btn" onClick={(e) => createCorrection(value)}>
                            {value.icon} {value.name}
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                }
                {correction !== undefined &&
                  <div>
                    <select value={correction?.type?.name} onChange={(e) => updateCorrectionType(e.target.value)}>
                      {Object.entries(CorrectionTypes).map(([key, value]) => {
                        return (
                          <option key={key} value={value.name}>
                            {value.name}
                          </option>
                        )
                      })}
                    </select>
                    <div className="border-between">
                      <div>
                        {correction.suggestions.length > 0 && correction.suggestions.map((value, key) => {
                          return (
                            <button type="button" contentEditable="false" className={'btn btn-suggestion btn-transparent ' + (correction?.activeSuggestion === key ? 'active' : '')}
                              onClick={() => setActiveSuggestion(key)} key={key} title={value} suppressContentEditableWarning={true}>
                              {value}
                            </button>
                          )
                        })}
                        {correction.correction.length > 0 && correction.activeSuggestion === undefined &&
                          <div>
                            <button type="button" className={'btn btn-suggestion btn-transparent active'}
                              title={correction.correction}
                              contentEditable={contentEditable}
                              suppressContentEditableWarning={true}
                              ref={buttonRef}
                              onClick={(e) => editButton()}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') blurButton(e.currentTarget.innerText)
                              }}
                              onBlur={(e) => blurButton(e.currentTarget.innerText)}
                              dangerouslySetInnerHTML={{ __html: correction.correction }}
                            >
                            </button>
                            <span onClick={(e) => removeCorrectedValue()}>
                              <Close />
                            </span>
                          </div>
                        }
                      </div>
                      <div>
                        <div>
                          <button type="button" className="btn btn-transparent mr-2"
                            onClick={() => addCorrectedValue()}>
                            Eigene Worteingabe
                          </button>
                          <button type="button"
                            className={!correction.strikedOut ? 'btn btn-transparent' : 'btn btn-transparent  text-strikethrough'}
                            onClick={() => strikeOut()}>
                            Durchstreichen
                          </button>
                        </div>
                      </div>
                      {editCorrection &&
                        <div className="d-flex justify-content-between mt-3">
                          <input
                            type="text"
                            className="form-control"
                            value={correctionValue}
                            onChange={(e) => setCorrectionValue(e.target.value)}
                            ref={inputRef}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') updateCorrectedValue(correctionValue)
                            }}
                            onBlur={(e) => updateCorrectedValue(correctionValue)}
                          />
                        </div>
                      }
                    </div>
                    <div className="border-between">
                      <textarea
                        placeholder="Kommentar eingeben ..."
                        value={correction.comment}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) e.currentTarget.blur()
                        }}
                        onChange={(e) => updateComment(e.target.value)}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        {(!correction.accepted && correction.suggestionType === 'errors') &&
                          <button type="button" className="btn btn-success no-border mr-1" onClick={(e) => acceptSuggestion(correction)}>
                            <Save color={'#fff'} />
                          </button>
                        }
                        {(correction.suggestionType === null || correction.suggestionType === 'errors') &&
                          <button type="button" className="btn btn-transparent" onClick={(e) => deleteCorrection(correction)}>
                            <Trash />
                          </button>
                        }
                      </div>
                      <div>
                        <button type="button" className="btn btn-transparent no-border"
                          onClick={() => prevCorrection()}>
                          <Prev opacity={currentIndex === 0 || corrections.length < 2 ? '0.3' : '1.0'} />
                        </button>
                        <button type="button" className="btn btn-transparent no-border"
                          onClick={() => nextCorrection()}>
                          <Next
                            opacity={(currentIndex === corrections.length - 1) || corrections.length < 2 ? '0.3' : '1.0'} />
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import './InkaToolbar.scss'
import ThumbUp from 'assets/icons/ThumbUp'
import ThumbDown from 'assets/icons/ThumbDown'
import Attention from 'assets/icons/Attention'
import Inka from 'assets/icons/Inka'
import ChevronUp from 'assets/icons/ChevronUp'
import ChevronDown from 'assets/icons/ChevronDown'
import Content from 'models/inka/Content'
import Structure from 'models/inka/Structure'
import Coherence from 'models/inka/Coherence'
import Vocabulary from 'models/inka/Vocabulary'
import Help from '../Help'
import InkaInfoBlock from './InkaInfoBlock'
import { Example } from 'context/exampleContext'

interface InkaToolbarProps {
  task?: string
  suggestions?: string
  example?: Example
}
export default function InkaToolbar (props: InkaToolbarProps): JSX.Element {
  const [show, setShow] = useState(true)

  const { example } = props
  const [content, setContent] = useState(new Content(''))
  const [coherence, setCoherence] = useState(new Coherence(''))
  const [vocabulary, setVocabulary] = useState(new Vocabulary(''))
  const [structure, setStructure] = useState(new Structure(''))
  const [suggestions, setSugestions] = useState('')

  useEffect(() => {
    if (example != null) {
      setSugestions(example.submission)
      setContent(new Content(example.rating.words))
      setCoherence(new Coherence(example.rating.connectors))
      setVocabulary(new Vocabulary(example.rating.vocabulary))
      setStructure(new Structure(example.rating.spelling))
    }
  }, [example])

  return (
    <div className="row sticky-top">
      <div className="col-12 inka-block">
        <div className="inka-box">
          <div className="task">
            Zu bewertende Aufgabe:
            <span>{props.task}</span>
          </div>
          <div className="inka-logo">
            <span>BETA</span>
            <Inka />
          </div>
          <div className="inka-toolbar">
            {show && (
              <div>
                <div className="row">
                  <div className="col-md-12 note">
                    <Attention /> <span>Hinweis: Ihr intelligenter Korrekturassistent INKA befindet sich in der Beta Phase. Die Vorhersagen können fehlerhaft sein. Die extrahierten Informationen und vorgeschlagenen Bewertungen sowie Korrekturen haben den Zweck Sie bei der Bewertung und Korrektur der Textabgaben zu unterstützen. Weitere Informationen erhalten Sie beim Klick auf das Fragezeichen.</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 blocks">
                    <div className="inka-block">
                      <h3>Inhalt <Help positions={['bottom']} ><div>INKA zählt die Wörter im Text und vergleicht den Textumfang mit der geforderten oder durchschnittlichen Wortanzahl bei dieser Aufgabe. Deutlich zu lange oder zu kurze Text werden entsprechend gekennzeichnet.</div></Help></h3>
                      <div className="info">
                        <div className="info-block">
                          <div className="info-description">Textumfang</div>
                          <div className="info-result">{example?.rating.wordCount} Worte</div>
                        </div>
                      </div>
                      <div className="feedback">
                        {content.helpful ? <ThumbUp /> : <ThumbDown />} <span
                          className={content.helpful ? 'text-success' : 'text-danger'}>{content.helpful ? 'Angemessen' : 'Nicht angemessen'}</span>
                      </div>
                    </div>
                    <div className="inka-block">
                      <h3>Kohärenz <Help positions={['bottom']} ><div>INKA überprüft und zählt, ob sich dem Lernstand entsprechende Konnektoren im Text befinden. Die erfassten Konnektoren können Sie sich im Text anzeigen lassen.</div></Help></h3>
                      <div className="info">
                        <InkaInfoBlock name='coherence' suggestion={suggestions} description="Konnektoren" />
                      </div>
                      <div className="feedback">
                        {coherence.helpful ? <ThumbUp /> : <ThumbDown />} <span
                          className={coherence.helpful ? 'text-success' : 'text-danger'}>{coherence.helpful ? 'Angemessen' : 'Nicht angemessen'}</span>
                      </div>
                    </div>
                    <div className="inka-block large">
                      <h3>Wortschatz <Help positions={['bottom']} ><div>INKA analysiert das Vokabular. Es wird überprüft, ob der aktuelle Lernwortschatz aus dem Kurs und - wenn vorhanden - die aktuellen Redemittel aus der Aufgabe im Text vorkommen. Außerdem erfolgt eine Einschätzung welchem Sprachniveau das verwendete Vokabular insgesamt zugeordnet werden kann. Den registrierten Lerntwortschatz und die Redemittel können Sie sich im Text anzeigen lassen.</div></Help></h3>
                      <div className="info">
                        <InkaInfoBlock name='vocabulary' suggestion={suggestions} description="Lernwortschatz" />
                        <InkaInfoBlock name='idioms' suggestion={suggestions} maxCount={example?.rating.maxIdioms} description="Redemittel" />
                        <div className="info-block" >
                          <div className="info-description">Sprachniveau</div>
                          <div className="info-result">{example?.rating.level}</div>
                        </div>
                      </div>
                      <div className="feedback">
                        {vocabulary.helpful ? <ThumbUp /> : <ThumbDown />} <span
                          className={vocabulary.helpful ? 'text-success' : 'text-danger'}>{vocabulary.helpful ? 'Angemessen' : 'Nicht angemessen'}</span>
                      </div>
                    </div>
                    <div className="inka-block">
                      <h3>STRUKTUREN <Help positions={['bottom']} ><div>INKA beurteilt, ob die verwendeten Strukturen dem Lernstand entsprechen. INKA erfasst auch Rechtschreibe-, Zeichensetzungs- und einfache Grammatikfehler. Sie können sich die Fehler im Text anzeigen lassen und übernehmen.</div></Help></h3>
                      <div className="info">
                        <div className="info-block">
                          <div className="info-description">Syntax</div>
                          <div className="info-result">{example?.rating.syntax}</div>
                        </div>
                        <InkaInfoBlock name='errors' suggestion={suggestions} description="Fehleranzahl:" />
                      </div>

                      <div className="feedback">
                        {structure.helpful ? <ThumbUp /> : <ThumbDown />} <span
                          className={structure.helpful ? 'text-success' : 'text-danger'}>{structure.helpful ? 'Angemessen' : 'Nicht angemessen'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="inka-toggle" onClick={() => setShow(!show)}>
          {show ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
    </div>
  )
}

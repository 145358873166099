import React, { useEffect, useState } from 'react'
import Correction from 'models/Correction'
import PopoverBordered from 'components/popover/PopoverBordered'
import { EditorContext } from '../../context/editorContext'
import { EditorContextType } from '../../@types/editor'
import CorrectionPopover from './CorrectionPopover'

interface CorrectionSpanProps {
  correction: Correction
  stateChanger?: (correction: Correction) => void
}

export default function SuggestionSpan (props: CorrectionSpanProps): JSX.Element {
  const defaultClasses = ['correction suggestion']
  const [classes, setClasses] = useState(defaultClasses)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const {
    suggestionsEnabled,
    correction
  } = React.useContext(EditorContext) as EditorContextType

  const changeCorrection = function (): void {
    if (suggestionsEnabled !== 'errors') {
      return
    }
    if (props.stateChanger != null) {
      props.stateChanger(props.correction)
    }
  }

  useEffect(() => {
    if (correction !== undefined && correction.id === props.correction.id) {
      setClasses(classes.concat('active'))
    } else {
      setClasses(defaultClasses)
    }
  }, [correction])

  const popoverContent = (
    <CorrectionPopover correction={props.correction} />
  )
  let popoverTimer: number | null = null

  return (
    <PopoverBordered popoverContent={popoverContent} opened={isPopoverOpen} positions={['top']} arrowColor={'#4AABD9'} padding={0} stateChanger={setIsPopoverOpen}>
      <span className={classes.join(' ')}
        onClick={() => changeCorrection()}
        onMouseEnter={() => {
          if (suggestionsEnabled !== 'errors') {
            return
          }
          popoverTimer = window.setTimeout(() => setIsPopoverOpen(true), 500)
        }}
        onMouseLeave={() => {
          if (popoverTimer !== null) {
            window.clearTimeout(popoverTimer)
          }
          setIsPopoverOpen(false)
        }}
        data-correction={props.correction.id}>
        {props.correction.tokens.map((token) => {
          return (
            <span key={token.id} data-id={token.id} dangerouslySetInnerHTML={{ __html: token.value }}></span>
          )
        })}
        <hr />
      </span>
    </PopoverBordered>
  )
}

import Token from 'models/Token'

export default class TokenService {
  text: string
  tokens: Token[] = []

  constructor (text: string, start: number = 0) {
    this.text = text

    const tokens: Token[] = []

    let tokenIndex: number = 0
    const rawTokens = text.split(' ')

    rawTokens.forEach((word, index) => {
      if (word.length > 0) {
        // We consider , and . as separate tokens
        const regex = /[.,:!?]/
        if (word.length > 1 && word.match(regex) !== null) {
          let charsBefore: string[] = []

          Array.from(word).forEach((char, index) => {
            if (char.match(regex) !== null) {
              if (charsBefore.length > 0) {
                tokens.push(new Token(start + tokenIndex, charsBefore.join('')))
                tokenIndex += 1
                charsBefore = []
              }
              tokens.push(new Token(start + tokenIndex, char))
              tokenIndex += 1
            } else {
              charsBefore.push(char)
            }
          })

          if (charsBefore.length > 0) {
            tokens.push(new Token(start + tokenIndex, charsBefore.join('')))
            tokenIndex += 1
          }
        } else {
          tokens.push(new Token(start + tokenIndex, word))
          tokenIndex += 1
        }
      }

      // add space as token if not last token
      if (index !== rawTokens.length - 1) {
        tokens.push(new Token(start + tokenIndex, '&nbsp;'))
        tokenIndex += 1
      }
    })

    this.tokens = tokens
  }

  getTokens (): Token[] {
    return this.tokens
  }
}

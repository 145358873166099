import React from 'react'

export default function Close (): JSX.Element {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L11.0718 12" stroke="#4AABD9" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M11.0718 1L1 12" stroke="#4AABD9" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  )
}

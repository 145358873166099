import React from 'react'
import PropTypes from 'prop-types'

interface ChevronDownProps {
  color?: string
}

ChevronDown.propTypes = {
  color: PropTypes.string
}

const defaultProps = {
  color: '#5F676B'
}

export default function ChevronDown (propsIn: ChevronDownProps): JSX.Element {
  const props = { ...defaultProps, ...propsIn }

  return (
    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6861 0.299805L6.50437 6.39907L0.305176 0.299805" stroke={props.color} strokeMiterlimit="10"/>
    </svg>
  )
}

import React from 'react'

export default function HelpIcon (): JSX.Element {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.84766 10.1833C9.79688 10.3442 9.76937 10.5938 9.76514 10.9324H8.05127C8.07666 10.2172 8.14437 9.7242 8.25439 9.45337C8.36442 9.1783 8.64795 8.86304 9.10498 8.50757L9.56836 8.14575C9.7207 8.03149 9.84342 7.90666 9.93652 7.77124C10.1058 7.53849 10.1904 7.28247 10.1904 7.00317C10.1904 6.68156 10.0952 6.38957 9.90479 6.1272C9.71859 5.8606 9.37581 5.72729 8.87646 5.72729C8.38558 5.72729 8.03646 5.89022 7.8291 6.21606C7.62598 6.54191 7.52441 6.88045 7.52441 7.23169H5.68994C5.74072 6.02563 6.16178 5.17082 6.95312 4.66724C7.45247 4.34562 8.06608 4.18481 8.79395 4.18481C9.75033 4.18481 10.5438 4.41333 11.1743 4.87036C11.8091 5.32739 12.1265 6.00448 12.1265 6.90161C12.1265 7.45174 11.9889 7.91512 11.7139 8.29175C11.5531 8.52026 11.2441 8.81226 10.7871 9.16772L10.3364 9.51685C10.091 9.70728 9.92806 9.92944 9.84766 10.1833ZM9.89844 13.6365H8.00684V11.802H9.89844V13.6365Z" fill="#47ABD8"/>
      <circle cx="9" cy="9" r="8" stroke="#47ABD8" strokeWidth="2"/>
    </svg>
  )
}

import React from 'react'

export default function Sheet (): JSX.Element {
  return (
    <svg width="47" height="43" viewBox="0 0 47 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_429_2325)">
        <path d="M0 41.4854H9.77681" stroke="#5AC8F5" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M0 32.542H47" stroke="#5AC8F5" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M0 22.8506H47" stroke="#5AC8F5" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M0 13.5371H47" stroke="#5AC8F5" strokeWidth="3" strokeMiterlimit="10"/>
        <path
          d="M13.4431 41.5865C11.7457 40.4507 14.784 32.8031 15.9382 31.0784L34.7026 2.93614C35.2544 2.11098 36.1141 1.5367 37.0927 1.33951C38.0714 1.14232 39.089 1.33834 39.922 1.88449V1.88449C40.7557 2.43019 41.3367 3.28179 41.5373 4.25207C41.7378 5.22234 41.5414 6.23185 40.9913 7.05862L22.2439 35.2008C21.0727 36.9171 15.1744 42.7223 13.4431 41.5865Z"
          fill="white" stroke="#A0C814" strokeWidth="3" strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_429_2325">
          <rect width="47" height="43" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

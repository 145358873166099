import React from 'react'

export default function Trash (): JSX.Element {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.69 5.35725L11.1322 16.6089H3.8678L3.30996 5.35725H11.69M12.3732 4H2.62678C2.46055 4 2.3011 4.07151 2.18355 4.19877C2.06601 4.32604 2 4.49864 2 4.67862L2.62678 17.3214C2.62678 17.5014 2.69279 17.674 2.81033 17.8013C2.92788 17.9285 3.08733 18 3.25357 18H11.7464C11.9127 18 12.0721 17.9285 12.1897 17.8013C12.3072 17.674 12.3732 17.5014 12.3732 17.3214L13 4.67862C13 4.49864 12.934 4.32604 12.8164 4.19877C12.6989 4.07151 12.5395 4 12.3732 4Z" fill="#47ABD8"/>
      <path d="M6 7V15" stroke="#47ABD8" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M9 7V15" stroke="#47ABD8" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M1 4H14" stroke="#47ABD8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 5V2.66086C4 1.74781 4.86558 1 5.9166 1H9.07652C10.1344 1 11 1.74781 11 2.66086V5" stroke="#47ABD8" strokeWidth="1.5" strokeMiterlimit="10"/>
    </svg>
  )
}

import React from 'react'

export default function Edit (): JSX.Element {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1469_14990)">
        <path d="M17.1721 7.75096V16.1946C17.1721 17.3375 16.2264 18.2727 15.0547 18.2727H2.8586C1.69403 18.2727 0.741211 17.3445 0.741211 16.1946V2.82606C0.741211 1.68315 1.68698 0.748047 2.8586 0.748047H12.2316" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.259 2.89536L7.47442 13.5556L4.45361 14.4076L5.26528 11.3806L16.0498 0.727295L18.259 2.89536Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1469_14990">
          <rect width="19" height="19" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'
import { Popover, ArrowContainer, PopoverPosition } from 'react-tiny-popover'
import Info from 'assets/icons/Info'
import './PopoverBordered.scss'
import Close from 'assets/icons/Close'

interface PopoverBorderedProps {
  children: JSX.Element
  popoverContent: JSX.Element
  opened: boolean
  info?: boolean
  close?: boolean
  positions: PopoverPosition[]
  maxWidth?: number
  padding?: number
  arrowColor?: string
  stateChanger?: (state: boolean) => void
}

export default function PopoverBordered (props: PopoverBorderedProps): JSX.Element {
  const arrowSize = 20
  const subArrow = 17

  const getTop = (position: PopoverPosition | undefined, size: number): number | undefined => {
    if (position === 'bottom') {
      return -size
    }

    return undefined
  }

  const getBottom = (position: PopoverPosition | undefined, size: number): number | undefined => {
    if (position === 'bottom') {
      return undefined
    }

    return -size
  }

  const getMarginTop = (position: PopoverPosition | undefined, size: number): number | undefined => {
    if (position === 'bottom') {
      return size - 4
    }

    return undefined
  }

  const getMarginBottom = (position: PopoverPosition | undefined, size: number): number | undefined => {
    if (position === 'top') {
      return size - 2
    }

    return undefined
  }

  const setIsPopoverOpen = (state: boolean): void => {
    props.stateChanger?.(state)
  }

  return (
    <Popover
      isOpen={props.opened}
      positions={props.positions}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <div
          onMouseLeave={() => setIsPopoverOpen(false)}
          onMouseEnter={() => setIsPopoverOpen(true)}
          className="help-container">
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={props.arrowColor ?? '#A0C813'}
            style={{
              maxWidth: props.maxWidth ?? 341,
              marginTop: getMarginTop(position, arrowSize),
              marginBottom: getMarginBottom(position, arrowSize),
              borderColor: props.arrowColor
            }}
            arrowStyle={{ top: getTop(position, arrowSize), bottom: getBottom(position, arrowSize) }}
            arrowSize={arrowSize}
            className='popover-help-container'
            arrowClassName={'popover-arrow'}
          >

            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'white'}
              style={{
                padding: props.padding ?? subArrow
              }}
              arrowStyle={{
                top: getTop(position, subArrow),
                bottom: getBottom(position, subArrow),
                borderBottomWidth: subArrow + 1,
                borderTopWidth: subArrow + 1
              }}
              arrowSize={subArrow}
              arrowClassName='popover-subarrow'
            >
              <div>
                {props.info === true && (
                  <span className="info">
                  <Info/>
                </span>
                )}
                {props.close === true && (
                  <span className="close" onClick={() => setIsPopoverOpen(false)}>
                  <Close/>
                </span>
                )}
                {props.popoverContent}
              </div>
            </ArrowContainer>
          </ArrowContainer>
        </div>
      )}>
      {props.children}
    </Popover>
  )
}

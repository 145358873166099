import React from 'react'
import Profile from 'components/Profile'
import './Student.scss'
import CollapsibleSection from '../components/CollapsibleSection'
import Revision from 'models/Revision'
import Revisions from 'components/revision/Revisions'
import Edit from 'assets/icons/Edit'
import Tokens from 'components/token/Tokens'
import Back from 'assets/icons/Back'
import teacher from 'assets/images/teacher.png'
import Mosaic from 'assets/icons/Mosaic'
import Sheet from 'assets/icons/Sheet'
import Folder from 'assets/icons/Folder'
import Attention from 'assets/icons/Attention'
import Download from 'assets/icons/Download'
import Mobile from 'assets/icons/Mobile'
import ChevronDown from 'assets/icons/ChevronDown'
import Select from 'assets/images/select.png'
import FeedbackDisplay from 'components/feedback/FeedbackDisplay'
import FeedbackCriterias from 'enums/FeedbackCriterias'
import FeedbackCriteria from 'models/FeedbackCriteria'
import Feedback from 'models/Feedback'
import FeedbackTypes from 'enums/FeedbackTypes'
import FeedbackItem from 'components/feedback/FeedbackItem'

export default function Student (): JSX.Element {
  // const content1 = 'Ich finde die <span data-type="" data-comment="Test comment" data-previous="aktuelle Zeitumstellung" data-strike-out="1">aktuelle Zeitumstellung</span> so wie sie ist absolut in Ordnung. Jedes Jahr freue ich mich im Frühjahr wie ein Wilder darauf das es länger hell ist, die Zeitumstellung verstärkt diesen Effekt noch mehr. Im Herbst stehe ich <span data-type="" data-comment="Test comment 2" data-previous="Zeitumstellung" data-strike-out="0">dann einfach nicht</span> aus meinem Bett auf und bleibe in der Stube, um Winterschlaf zu halten.'
  const content1 = 'Meine freundin heißt Salome Scrocchi, sie ist sechsundzwanzig jahre alt, sie ist ärztin von Beruf, sie ist aktiv und ehrlich, ihre hobbys sind interwegs und sehen Netflix'

  const revisions = [
    new Revision(2, content1)
  ]

  const initialFeedbacks = Object.values(FeedbackCriterias).map((value: FeedbackCriteria) => {
    return new Feedback(value, Number(Object.keys(FeedbackTypes).sort(() => Math.random() - 0.5)[0]))
  })

  return (
    <div className="container student">
      <div className="row">
        <div className="col-12">
          <Profile name="John Ming" task="Wie ist Ihre Freundin/Ihr Freund?"/>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CollapsibleSection collapsible={false} title="AUFGABENSTELLUNG" border={false}>
            <div className="task-description">
              <p>
              Stellen Sie eine Freundin/einen Freund vor (Alter, Beruf, Hobbys ...) Beschreiben Sie sie/ihn. Sagen Sie auch was Sie gern mit ihr/ihm machen und begründen Sie. Schreiben Sie einen kurzen Text. Die Sätze in der Box helfen Ihnen. Ihre Tutorin/ihr Tutor korrigiert Ihren Text und gibt Ihnen Feedback.
              </p>
              <div>
                <img src={Select} alt=""/>
              </div>
            </div>
          </CollapsibleSection>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <CollapsibleSection collapsible={false} title="Version der Abgabe">
            <div>
              <Revisions revisions={revisions} emptyText="John, ihre Abgabe wurde bewertet." padding="17px"/>
            </div>
          </CollapsibleSection>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <CollapsibleSection collapsible={false} icon={<Edit/>} title="DER VON HERRN MAURER KORRIGIERTE TEXT">
            <div className="stud-corrections">
              <Tokens tokens={revisions[0].tokens} corrections={[]}/>
            </div>
          </CollapsibleSection>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <CollapsibleSection collapsible={false} icon={<Back/>} title="FEEDBACK KOMMENTAR von HerrN MAURER">
            <div className="teacher-feedback">
              <img src={teacher} alt=""/>
              <span>Man kann gut sehen wie sie Fortschritte in der Satzbildung
machen und es ihnen immer leichter fällt die Lernwörter zu nutzen.
Weiter so! Viele Grüße! A.Maurer</span>
            </div>
          </CollapsibleSection>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <CollapsibleSection collapsible={false} icon={<Mosaic/>} title="IHRE DETAILLIERTE BEWERTUNG">
            <div className="student-feedbacks">
              <div className="row feedback-list">
                {initialFeedbacks.map((feedback: Feedback, index: number) => {
                  return (
                    <FeedbackItem border={index !== (initialFeedbacks.length - 1)} key={feedback.criteria.id} feedback={feedback} />
                  )
                })}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FeedbackDisplay feedbacks={initialFeedbacks} />
                </div>
              </div>
            </div>
          </CollapsibleSection>
        </div>
      </div>
      <div className="row suggestions">
        <div className="col-md-12 suggestions-header">
          <p>
            Möchten sie sich weiter verbessern? <br />
            Hier finden sie die passenden Hilfsmittel zur aktuellen Aufgabe
          </p>
          <div>
            <ChevronDown color="#47ABD8" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="suggestion">
            <h3><Sheet />Probieren sie doch mal IHREN Grammatiktrainer</h3>
            <div className="link">
              <Attention /> <span>Weitere Details</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="suggestion">
            <h3><Sheet />DIESE REDEMITTEL HABEN SIE BISHER NOCH NICHT BENUTZT:</h3>
            <div className="content">
              <ul>
                <li>
                  Besonders wichtig erscheint mir, …
                </li>
                <li>
                  Erstens zweitens …..
                </li>
                <li>
                  Meiner Meinung nach….
                </li>
                <li>
                  Meines Erachtens…
                </li>
                <li>
                  Ich möchte auch noch erwähnen, …
                </li>
                <li>
                  Im Gegensatz zu ….
                </li>
                <li>
                  Im Vergleich zu meinem Heimatland….
                  Anders als in Deutschland...
                </li>
                <li>
                  Außerdem möchte ich noch betonen, dass…
                </li>
                <li>
                  Ich persönlich glaube
                </li>
              </ul>
            </div>
            <div className="link">
              <Download /> <span>Redemittel als PDF herunterladen </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="suggestion">
            <h3><Folder />KENNEN SIE SCHON IHREN A1 VOKABELTRAINER?</h3>
            <div className="link">
              <Mobile /> <span>Vokabeltrainer nutzen </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="submit-buttons">
            <button type="button" className="btn btn-success">
              NEUE ABGABE ERSTELLEN
            </button>
            <a className="btn btn-outline-light">
              WEITER ohne neue ABGABE
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

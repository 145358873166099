import React, { useState } from 'react'
import FeedbackTypes from 'enums/FeedbackTypes'
import './FeedbackEditor.scss'
import Help from '../Help'
import FeedbackDisplay from './FeedbackDisplay'
import FeedbackCriterias from 'enums/FeedbackCriterias'
import Feedback from 'models/Feedback'
import FeedbackCriteria from 'models/FeedbackCriteria'

export default function FeedbackEditor (): JSX.Element {
  const initialFeedbacks = Object.values(FeedbackCriterias).map((value: FeedbackCriteria) => {
    return new Feedback(value, undefined)
  })

  const [feedbacks, setFeedbacks] = useState<Feedback[]>(initialFeedbacks)

  const addFeedback = (criteria: FeedbackCriteria, type: string): void => {
    const updatedFeedbacks = feedbacks.map((feedback) =>
      feedback.criteria.id === criteria.id ? new Feedback(criteria, type.length > 0 ? Number(type) : undefined) : feedback
    )

    setFeedbacks(updatedFeedbacks)
  }

  return (
    <div className="feedback-editor">
      <div className="row controls">
        {Object.entries(FeedbackCriterias).map(([index, criteria]) => {
          return (
            <div key={criteria.id} className="col-md-3">
              <label htmlFor="">
                {criteria.name} <Help positions={['bottom']}>
                <div>{criteria.description}</div>
              </Help>
                <select className="form-control" value={feedbacks[criteria.id].score}
                        onChange={(e) => addFeedback(criteria, e.target.value)}>
                  <option value="">BEWERTEN</option>
                  {Object.entries(FeedbackTypes).reverse().map(([key, value]) => {
                    return (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    )
                  })}
                </select>
              </label>
            </div>
          )
        })}
      </div>
      <FeedbackDisplay feedbacks={feedbacks}/>
    </div>
  )
}

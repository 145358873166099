import FeedbackCriteria from 'models/FeedbackCriteria'

const FeedbackCriterias = {
  0: new FeedbackCriteria(0, 'Inhalt', 'Beantwortete Fragestellung, Textformat und Umfang.', 'Inhaltliche Erfüllung der Aufgabe entsprechend des Lernstands im Kurs: passende Textsorte, Textumfang & situative Angemessenheit.'),
  1: new FeedbackCriteria(1, 'Kohärenz', 'Der Textaufbau mit Verknüpfungen', 'Klarer Textaufbau & Verknüpfungen von Sätzen und Satzteilen entsprechend des Lernstands im Kurs.'),
  2: new FeedbackCriteria(2, 'Wortschatz', 'Nutzung der Lernwörter', 'Verwendung des Wortschatzes entsprechend des Lernstands im Kurs; Gebrauch des aktuellen Lernwortschatzes und der aktuellen Redemittel.'),
  3: new FeedbackCriteria(3, 'Strukturen', 'Grammatik und Fehlerzahl', 'Verwendung & Beherrschung adäquater grammatikalischer Strukturen entsprechend des aktuellen Lernstands im Kurs.')
}

export default FeedbackCriterias

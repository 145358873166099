import React, { useEffect, useState } from 'react'
import Correction from 'models/Correction'
import './CorrectionSpan.scss'
import PopoverBordered from '../popover/PopoverBordered'
import { EditorContext } from 'context/editorContext'
import { EditorContextType } from '../../@types/editor'
import Trash from 'assets/icons/Trash'

interface CorrectionSpanProps {
  correction: Correction
  editor?: boolean
}

export default function CorrectionSpan (props: CorrectionSpanProps): JSX.Element {
  const defaultClasses = ['correction']
  const [classes, setClasses] = useState(defaultClasses)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const {
    correction,
    handleMouseUp,
    deleteCorrection,
    setCorrection
  } = React.useContext(EditorContext) as EditorContextType

  useEffect(() => {
    if (correction !== undefined && correction.id === props.correction.id) {
      setClasses(defaultClasses.concat('active'))
    } else {
      setClasses(defaultClasses)
    }
  }, [correction])

  const changeCorrection = function (): void {
    const sel = window.getSelection()
    if (sel === null || sel.isCollapsed) {
      setCorrection(props.correction)
    }
  }

  const popoverContent = (
    <div className="popover-content">
      {props.editor === true &&
        <button type="button" className="btn btn-transparent btn-delete" onClick={() => deleteCorrection(props.correction)}>
          <Trash />
        </button>
      }
      <span className="head">{props.correction.type.name}</span>
      <span
        className={props.correction.strikedOut ? 'text-strikethrough subtitle' : 'subtitle'}>
                {props.correction.correction.length > 0 ? props.correction.correction : ''}
              </span>
      <span className="comment">{props.correction.comment ?? ''}</span>
    </div>
  )
  let popoverTimer: number | null = null
  const renderedIds: Array<number | string> = []

  return (
    <PopoverBordered popoverContent={popoverContent}
                     opened={isPopoverOpen}
                     positions={['top']}
                     arrowColor={'#4AABD9'}
                     stateChanger={setIsPopoverOpen}
                     padding={17}>

      <span className={classes.join(' ')}
            onMouseUpCapture={() => changeCorrection()}
            onMouseDown={handleMouseUp}
            onMouseEnter={() => {
              popoverTimer = window.setTimeout(() => setIsPopoverOpen(true), 500)
            }}
            onMouseLeave={() => {
              if (popoverTimer !== null) {
                window.clearTimeout(popoverTimer)
              }
              setIsPopoverOpen(false)
            }}
            data-correction={props.correction.id}>
            {props.correction.type.icon}
        {props.correction.tokens.map((token) => {
          const child = props.correction.children.find(c => c.tokens.find(t => t.id === token.id))

          if (child !== undefined) {
            if (renderedIds.includes(child.id)) {
              return null
            }
            renderedIds.push(child.id)

            return (
              <CorrectionSpan key={token.id} correction={child} editor={props.editor}/>
            )
          }

          return (
            <span className={props.correction.strikedOut ? 'text-strikethrough' : ''}
                  key={token.id}
                  data-id={token.id}
                  dangerouslySetInnerHTML={{ __html: token.value }}></span>
          )
        })}
        <hr/>
      </span>
    </PopoverBordered>
  )
}

import React from 'react'

export default function SmilySheet (): JSX.Element {
  return (
    <svg width="84" height="106" viewBox="0 0 84 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_41_2504)">
        <path
          d="M20.9542 23.6626L20.9649 23.6613L20.9755 23.6596C21.8392 23.5173 22.7243 23.7147 23.445 24.2103L23.4661 24.2248L23.4885 24.237C23.5486 24.2699 23.6014 24.3148 23.6436 24.3688C23.6858 24.4228 23.7164 24.4847 23.7337 24.5509C23.7509 24.6171 23.7545 24.6861 23.744 24.7538C23.7345 24.8162 23.7132 24.8761 23.6814 24.9307C23.5993 25.0374 23.4795 25.1095 23.3461 25.1318C23.2076 25.1549 23.0657 25.1225 22.9511 25.0418C22.6848 24.8517 22.3836 24.7157 22.0647 24.6419C21.7453 24.5679 21.4145 24.5576 21.0911 24.6116C20.7677 24.6655 20.4581 24.7826 20.18 24.9563C19.9029 25.1293 19.6626 25.355 19.4727 25.6206C19.431 25.677 19.3784 25.7244 19.3179 25.7598C19.2567 25.7958 19.1887 25.8187 19.1182 25.8274C19.0478 25.836 18.9763 25.8301 18.9082 25.8101C18.8403 25.79 18.7771 25.7562 18.7227 25.7108C18.6683 25.6654 18.6239 25.6094 18.5921 25.5463L18.2041 25.7415L18.5921 25.5463C18.5604 25.4832 18.5419 25.4141 18.5379 25.3436C18.5339 25.2731 18.5444 25.2024 18.5689 25.136C18.5933 25.0696 18.6312 25.0088 18.6801 24.9576L18.6963 24.9405L18.7109 24.9221C19.2625 24.2226 20.0691 23.7696 20.9542 23.6626Z"
          stroke="#A0C814" strokeMiterlimit="10"/>
        <path
          d="M45.7898 20.4717L45.7991 20.478L45.8088 20.4839C45.9265 20.556 46.0107 20.6717 46.0429 20.8056C46.0751 20.9394 46.0527 21.0807 45.9806 21.1983C45.9085 21.3159 45.7925 21.4002 45.6579 21.4325C45.5284 21.4637 45.3922 21.4441 45.2771 21.3783C45.0157 21.1923 44.7206 21.0589 44.4083 20.9853C44.0881 20.9099 43.756 20.8988 43.4314 20.953C43.1068 21.0071 42.7962 21.1254 42.5179 21.3007L42.7843 21.7238L42.5179 21.3007C42.2417 21.4745 42.0026 21.7011 41.8143 21.9676C41.7299 22.0791 41.6057 22.1543 41.4674 22.1774C41.3371 22.1991 41.2037 22.173 41.0916 22.1046C41.0485 22.0626 41.0129 22.0136 40.9863 21.9597C40.9553 21.8971 40.9371 21.8291 40.9325 21.7594C40.9279 21.6897 40.9372 21.6198 40.9597 21.5536C40.9822 21.4874 41.0176 21.4262 41.0638 21.3737L41.0815 21.3536L41.0969 21.3317C41.6113 20.6021 42.3915 20.1029 43.2702 19.9411C44.1474 19.7826 45.0517 19.9731 45.7898 20.4717Z"
          stroke="#A0C814" strokeMiterlimit="10"/>
        <path
          d="M52.5591 5.45153L8.71892 12.7651C5.37437 13.3231 3.11485 16.4834 3.67214 19.824L13.1338 76.5402C13.6911 79.8808 16.8541 82.1365 20.1987 81.5786L64.0389 74.265C67.3834 73.707 69.6429 70.5467 69.0856 67.2061L59.624 10.4899C59.0667 7.14932 55.9037 4.89358 52.5591 5.45153Z"
          stroke="#A0C814" strokeWidth="4" strokeMiterlimit="10"/>
        <path
          d="M20 34.8779C22.0885 36.8453 24.6043 38.3034 27.3509 39.1384C30.0975 39.9733 33.0005 40.1625 35.8329 39.691V39.691C38.6653 39.2186 41.3501 38.0978 43.6771 36.4164C46.0041 34.735 47.9101 32.5385 49.2459 29.999"
          stroke="#A0C814" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M65.232 25.5383L55.8321 38.6858L58.9309 57.2615L69.5866 42.3577L65.232 25.5383Z" fill="white"/>
        <path d="M46.1885 43.4768L55.999 50.4732L74.3684 24.7803" stroke="#47ABD8" strokeWidth="4"
              strokeMiterlimit="10"/>
        <path d="M22 63.9385L57.6031 57.999" stroke="#A0C814" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M23 70.9385L50 66.499" stroke="#A0C814" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M26.4824 84.8252L44.7073 81.7848" stroke="#A0C814" strokeWidth="2" strokeMiterlimit="10"/>
      </g>
      <ellipse cx="41.085" cy="101.228" rx="31.8369" ry="3.41109" transform="rotate(5.43489 41.085 101.228)"
               fill="#D9D9D9"/>
      <defs>
        <clipPath id="clip0_41_2504">
          <rect width="72.6413" height="74.1238" fill="white" transform="translate(0.151855 11.9844) rotate(-9.47109)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import FeedbackCriteria from './FeedbackCriteria'

export default class Feedback {
  criteria: FeedbackCriteria
  score: number | undefined
  constructor (criteria: FeedbackCriteria, score: number | undefined) {
    this.criteria = criteria
    this.score = score
  }
}

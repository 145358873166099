import React from 'react'
import './App.scss'

import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom'

import Teacher from './pages/Teacher'
import Student from './pages/Student'
import Main from './pages/Main'
import ExampleProvider from 'context/exampleContext'

const router = createBrowserRouter([
  {
    path: '/teacher',
    element: <Navigate replace to="/teacher/1" />
  },
  {
    path: '/teacher/:id',
    element: <ExampleProvider>
      <Teacher />
    </ExampleProvider>
  },
  {
    path: '/student',
    element: <Student />
  },
  {
    path: '/',
    element: <Main />
  }
])

function App (): JSX.Element {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  )
}

export default App

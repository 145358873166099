import React, { useState } from 'react'
import './CollapsibleSection.scss'
import Minus from 'assets/icons/Minus'
import PropTypes from 'prop-types'

interface CollapsibleSectionProps {
  collapsible: boolean
  icon?: JSX.Element
  children: JSX.Element
  title: string
  border?: boolean
}

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  collapsible: PropTypes.bool.isRequired,
  border: PropTypes.bool,
  icon: PropTypes.element,
  children: PropTypes.element
}

const defaultProps = {
  border: true
}

export default function CollapsibleSection (propsIn: CollapsibleSectionProps): JSX.Element {
  const props = { ...defaultProps, ...propsIn }
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div className={'heading-row ' + (props.border ? 'border-top' : '')}>
      <h3>{props.icon} {props.title} {props.collapsible && <button onClick={() => setCollapsed(!collapsed)} className="btn btn-link float-right" ><Minus /></button>}</h3>
      {!collapsed &&
        <div className="row-content">
          {props.children}
        </div>
      }
    </div>
  )
}

import React from 'react'
import './Profile.scss'
import defaultAvatar from 'assets/images/profile.png'
interface ProfileProps {
  name: string
  task: string
}

export default function Profile (props: ProfileProps): JSX.Element {
  return (
    <div className="profile">
      <div className="name">
        <img src={defaultAvatar} alt="User picture"/>
        <span>{props.name}</span>
      </div>
      {props.task.length > 0 &&
        <div className="task">
          Zu bewertende Aufgabe:
          <span>{props.task}</span>
        </div>
      }
    </div>
  )
}

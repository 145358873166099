const FeedbackTypes = {
  100: 'SEHR GUT',
  75: 'GUT',
  50: 'BEFRIEDIGEND',
  25: 'AUSREICHEND',
  1: 'UNGENÜGEND',
  0: 'KEINE WERTUNG'
}

export default FeedbackTypes

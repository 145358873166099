import React from 'react'

interface NextProps {
  opacity: string
}

export default function Next (props: NextProps): JSX.Element {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={props.opacity} clipPath="url(#clip0_912_4490)">
         <path d="M12.8333 1.78943L20.1667 8.94732L12.8333 16.1052" stroke="#47ABD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.91666 8.94739H19.25" stroke="#47ABD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_912_4490">
          <rect width="22" height="17" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React, { useState } from 'react'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import './Wysiwyg.scss'

interface WysiwygProps {
  content: string
}

export default function Wysiwyg (propsIn: WysiwygProps): JSX.Element {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  )

  return (
        <Editor editorState={editorState} onEditorStateChange={setEditorState} toolbar={{
          options: ['inline', 'history']
        }}/>
  )
}

import React, { useEffect, useState } from 'react'
import {
  useParams
} from 'react-router-dom'
import examples from '../assets/data/examples.json'

export interface ExampleContextType {
  currentExample?: Example
  currentExampleIndex?: number
  setCurrentExampleIndex?: (v: number) => void
}

interface ExampleProviderProps {
  children?: JSX.Element
}

interface Rating {
  connectors: string
  level: string
  maxIdioms: number
  spelling: string
  syntax: string
  vocabulary: string
  wordCount: number
  words: string
}

export interface Example {
  chapter: string
  task: string
  idioms: string[]
  idiomsHeader: string
  submission: string
  rating: Rating
}
export const ExampleContext = React.createContext<ExampleContextType | null>(null)

export default function ExampleProvider (props: ExampleProviderProps): JSX.Element {
  const initialExample: Example = examples[0] as unknown as Example
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0)
  const [currentExample, setCurrentExample] = useState(initialExample)
  const { id = '1' } = useParams()

  useEffect(() => {
    if (!(currentExampleIndex >= 0 && currentExampleIndex < examples.length)) {
      setCurrentExampleIndex(0)
    } else {
      setCurrentExample(examples[currentExampleIndex] as unknown as Example)
    }
  }, [currentExampleIndex])

  useEffect(() => {
    let index = parseInt(id) - 1
    if (!(index >= 0 && index < examples.length)) {
      index = 0
    }
    setCurrentExampleIndex(index)
  }, [id])

  return <ExampleContext.Provider value={{
    currentExample,
    currentExampleIndex,
    setCurrentExampleIndex
  }}> {props.children}</ExampleContext.Provider >
}

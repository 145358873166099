import React from 'react'

interface PrevProps {
  opacity: string
}

export default function Prev (props: PrevProps): JSX.Element {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={props.opacity} clipPath="url(#clip0_912_4493)">
         <path d="M9.16667 15.2106L1.83333 8.05268L9.16667 0.894783" stroke="#47ABD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.0833 8.05261L2.75 8.05261" stroke="#47ABD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_912_4493">
          <rect width="22" height="17" fill="white" transform="translate(22 17) rotate(-180)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'
export default function Inka (): JSX.Element {
  return (
    <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g opacity="0.7" clipPath="url(#clip0_1401_2607)">
          <path d="M33.4431 10.2524H4.17676V11.8655H33.4431V10.2524Z" fill="#003969"/>
          <path d="M24.2167 14.0525C25.8769 14.0525 27.2227 12.71 27.2227 11.0539C27.2227 9.3979 25.8769 8.05542 24.2167 8.05542C22.5565 8.05542 21.2107 9.3979 21.2107 11.0539C21.2107 12.71 22.5565 14.0525 24.2167 14.0525Z" fill="white"/>
          <path d="M24.2069 14.8541C23.4538 14.8521 22.7183 14.6276 22.0931 14.2088C21.4679 13.7901 20.9812 13.1959 20.6944 12.5014C20.4075 11.8068 20.3335 11.0431 20.4816 10.3066C20.6298 9.57007 20.9934 8.89387 21.5265 8.36341C22.0597 7.83294 22.7385 7.47203 23.4772 7.3262C24.216 7.18037 24.9814 7.25622 25.677 7.54413C26.3725 7.83204 26.9669 8.31909 27.385 8.9438C27.8032 9.56851 28.0264 10.3028 28.0264 11.054C28.0264 11.5538 27.9275 12.0488 27.7355 12.5105C27.5434 12.9722 27.2619 13.3916 26.9071 13.7446C26.5523 14.0976 26.1311 14.3773 25.6678 14.5677C25.2044 14.7581 24.708 14.8554 24.2069 14.8541V14.8541ZM24.2069 8.85706C23.7713 8.85706 23.3455 8.98592 22.9833 9.22732C22.6211 9.46872 22.3388 9.81181 22.1721 10.2132C22.0054 10.6147 21.9618 11.0564 22.0468 11.4826C22.1318 11.9087 22.3415 12.3002 22.6496 12.6075C22.9576 12.9147 23.35 13.1239 23.7772 13.2087C24.2044 13.2935 24.6473 13.25 25.0497 13.0837C25.4521 12.9174 25.7961 12.6358 26.0381 12.2745C26.2801 11.9133 26.4093 11.4885 26.4093 11.054C26.4093 10.4713 26.1773 9.91252 25.7642 9.50051C25.3512 9.08851 24.791 8.85706 24.2069 8.85706V8.85706Z" fill="#003969"/>
          <path d="M26.697 2.19702H11.7463V3.81008H26.697V2.19702Z" fill="#003969"/>
          <path d="M17.4407 5.19538C18.6571 5.19538 19.6431 4.21178 19.6431 2.99845C19.6431 1.78511 18.6571 0.801514 17.4407 0.801514C16.2243 0.801514 15.2383 1.78511 15.2383 2.99845C15.2383 4.21178 16.2243 5.19538 17.4407 5.19538Z" fill="white"/>
          <path d="M17.4408 5.98718C16.8522 5.98718 16.2768 5.8131 15.7874 5.48688C15.2979 5.16066 14.9164 4.69698 14.6912 4.1545C14.4659 3.61202 14.407 3.01507 14.5218 2.43917C14.6366 1.86328 14.9201 1.33432 15.3363 0.919121C15.7526 0.503923 16.2829 0.22116 16.8602 0.106608C17.4375 -0.00794531 18.036 0.0508455 18.5798 0.275549C19.1237 0.500253 19.5885 0.880766 19.9155 1.36899C20.2425 1.85721 20.4171 2.43118 20.4171 3.01836C20.4171 3.80574 20.1035 4.56089 19.5454 5.11765C18.9872 5.67441 18.2302 5.98718 17.4408 5.98718V5.98718ZM17.4408 1.60322C17.1638 1.60126 16.8924 1.68147 16.661 1.83359C16.4297 1.98572 16.2489 2.20293 16.1415 2.45771C16.0341 2.7125 16.0049 2.99335 16.0578 3.26467C16.1106 3.53599 16.2431 3.78556 16.4383 3.98169C16.6335 4.17783 16.8828 4.31168 17.1544 4.36632C17.426 4.42097 17.7077 4.39396 17.9639 4.28865C18.2201 4.18333 18.4392 4.00449 18.5933 3.77482C18.7475 3.54514 18.8298 3.275 18.8298 2.99861C18.8298 2.63024 18.6838 2.27679 18.4236 2.0154C18.1634 1.754 17.8101 1.60583 17.4408 1.60322V1.60322Z" fill="#003969"/>
          <path d="M32.6494 4.74026C33.6137 4.74026 34.3954 3.96047 34.3954 2.99855C34.3954 2.03663 33.6137 1.25684 32.6494 1.25684C31.6851 1.25684 30.9033 2.03663 30.9033 2.99855C30.9033 3.96047 31.6851 4.74026 32.6494 4.74026Z" fill="white"/>
          <path d="M32.6493 5.54193C32.1422 5.54389 31.646 5.39554 31.2236 5.11574C30.8011 4.83595 30.4715 4.4373 30.2766 3.97036C30.0816 3.50342 30.0301 2.98926 30.1285 2.49307C30.227 1.99688 30.471 1.541 30.8295 1.18334C31.1881 0.825674 31.6451 0.582338 32.1425 0.484138C32.6399 0.385938 33.1554 0.437288 33.6235 0.631758C34.0916 0.826229 34.4912 1.15505 34.7717 1.57643C35.0522 1.99781 35.2009 2.49282 35.199 2.99863C35.1964 3.67235 34.9269 4.31771 34.4493 4.79411C33.9717 5.2705 33.3247 5.53933 32.6493 5.54193V5.54193ZM32.6493 2.05849C32.5255 2.05849 32.403 2.08282 32.2886 2.13006C32.1743 2.17731 32.0704 2.24656 31.9829 2.33386C31.8954 2.42116 31.8259 2.52476 31.7786 2.63882C31.7312 2.75288 31.7068 2.87517 31.7068 2.99863C31.7068 3.12209 31.7312 3.24432 31.7786 3.35838C31.8259 3.47244 31.8954 3.5761 31.9829 3.6634C32.0704 3.7507 32.1743 3.81995 32.2886 3.8672C32.403 3.91444 32.5255 3.93871 32.6493 3.93871C32.8478 3.87363 33.0206 3.74772 33.1431 3.5789C33.2657 3.41009 33.3316 3.20704 33.3316 2.99863C33.3316 2.79022 33.2657 2.58711 33.1431 2.4183C33.0206 2.24948 32.8478 2.12356 32.6493 2.05849Z" fill="#003969"/>
          <path d="M40.7348 18.1692H18.2642V19.7823H40.7348V18.1692Z" fill="#003969"/>
          <path d="M11.7959 21.4744C13.1767 21.4744 14.296 20.3579 14.296 18.9806C14.296 17.6033 13.1767 16.4868 11.7959 16.4868C10.4152 16.4868 9.2959 17.6033 9.2959 18.9806C9.2959 20.3579 10.4152 21.4744 11.7959 21.4744Z" fill="white"/>
          <path d="M11.7958 22.2663C11.1424 22.2663 10.5037 22.073 9.96041 21.7109C9.41713 21.3488 8.9937 20.8342 8.74365 20.232C8.49361 19.6299 8.4282 18.9673 8.55567 18.3281C8.68314 17.6888 8.99778 17.1016 9.4598 16.6408C9.92182 16.1799 10.5105 15.866 11.1513 15.7389C11.7922 15.6117 12.4564 15.677 13.0601 15.9264C13.6637 16.1758 14.1797 16.5982 14.5427 17.1401C14.9057 17.682 15.0994 18.3191 15.0994 18.9709C15.0994 19.8449 14.7514 20.6831 14.1318 21.3011C13.5123 21.9191 12.672 22.2663 11.7958 22.2663V22.2663ZM11.7958 17.3183C11.4594 17.3163 11.1301 17.4141 10.8496 17.5993C10.5691 17.7845 10.3501 18.0487 10.2205 18.3583C10.0909 18.6679 10.0564 19.009 10.1216 19.3382C10.1867 19.6674 10.3485 19.9698 10.5863 20.2071C10.8242 20.4443 11.1274 20.6057 11.4574 20.6707C11.7874 20.7357 12.1293 20.7013 12.4397 20.572C12.7501 20.4427 13.0149 20.2242 13.2006 19.9445C13.3862 19.6647 13.4843 19.3362 13.4823 19.0006C13.485 18.7776 13.4433 18.5562 13.3599 18.3493C13.2764 18.1423 13.1528 17.9538 12.996 17.7947C12.8393 17.6356 12.6526 17.509 12.4466 17.4221C12.2406 17.3352 12.0194 17.2899 11.7958 17.2886V17.3183Z" fill="#003969"/>
          <path d="M29.6633 20.7224C30.6276 20.7224 31.4094 19.9426 31.4094 18.9807C31.4094 18.0188 30.6276 17.239 29.6633 17.239C28.699 17.239 27.9172 18.0188 27.9172 18.9807C27.9172 19.9426 28.699 20.7224 29.6633 20.7224Z" fill="white"/>
          <path d="M29.6632 21.5241C29.1566 21.5261 28.6607 21.378 28.2385 21.0986C27.8163 20.8193 27.4867 20.4212 27.2914 19.9549C27.0962 19.4885 27.0441 18.9748 27.1417 18.4789C27.2394 17.983 27.4824 17.5271 27.8399 17.1691C28.1975 16.811 28.6535 16.5668 29.1503 16.4675C29.6471 16.3682 30.1622 16.4182 30.6305 16.6111C31.0988 16.8041 31.4991 17.1313 31.7808 17.5514C32.0625 17.9715 32.2129 18.4655 32.2129 18.9709C32.2103 19.6455 31.9411 20.292 31.4638 20.7699C30.9865 21.2479 30.3395 21.5189 29.6632 21.5241V21.5241ZM29.6632 18.0308C29.4814 18.0406 29.3064 18.1028 29.1592 18.2097C29.0121 18.3167 28.8991 18.4638 28.834 18.6334C28.7689 18.803 28.7545 18.9878 28.7924 19.1654C28.8304 19.343 28.919 19.5059 29.0478 19.6343C29.1765 19.7627 29.3398 19.8512 29.5178 19.889C29.6959 19.9269 29.8812 19.9125 30.0512 19.8475C30.2212 19.7826 30.3687 19.6699 30.476 19.5231C30.5832 19.3763 30.6455 19.2018 30.6553 19.0204C30.6625 18.8886 30.6417 18.7567 30.5944 18.6334C30.5471 18.5101 30.4742 18.3981 30.3806 18.3048C30.2871 18.2114 30.1748 18.1388 30.0512 18.0916C29.9276 18.0444 29.7954 18.0237 29.6632 18.0308V18.0308Z" fill="#003969"/>
          <path d="M-7.92675e-05 30.4502H2.04361V39.99H-0.0100098L-7.92675e-05 30.4502Z" fill="#003969"/>
          <path d="M15.8137 39.9901L12.5398 34.8244L11.9644 33.9634V40H10.1985V30.4602H11.9644L15.516 36.2494V35.2598V30.4998H17.272V40.0396L15.8137 39.9901Z" fill="#003969"/>
          <path d="M30.3081 39.9901L28.1652 35.9425H27.312V40.0098H25.3279V30.47H27.312V34.2899H28.2248L30.2883 30.47H32.5602L30.0204 34.9529L32.699 39.9504L30.3081 39.9901Z" fill="#003969"/>
          <path d="M44.9513 38.1098H41.8857L41.4195 39.99H39.2964L42.2726 30.4502H44.7032L47.6795 39.99H45.4374L44.9513 38.1098ZM43.1754 33.043L42.3322 36.368H44.5147L43.6715 33.043L43.4532 32.0534L43.1754 33.043Z" fill="#003969"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1401_2607">
          <rect width="47.6596" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

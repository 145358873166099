import React, { useEffect, useState } from 'react'
import './FeedbackDisplay.scss'
import Help from '../Help'
import FeedbackTypes from 'enums/FeedbackTypes'
import Feedback from 'models/Feedback'

interface FeedbackDisplayProps {
  feedbacks: Feedback[]
}

export default function FeedbackDisplay (props: FeedbackDisplayProps): JSX.Element {
  const [score, setScore] = useState(0)
  const [skipped, setSkipped] = useState(false)
  const [scoreType, setScoreType] = useState('')

  useEffect(() => {
    let s = 0
    let skip = false

    props.feedbacks.forEach((feedback) => {
      if (feedback.score !== undefined) {
        s = s + feedback.score
      } else {
        skip = true
      }
    })

    setSkipped(skip)

    let avg = Math.round(s / props.feedbacks.length)
    setScore(avg)

    avg = Math.ceil(avg / 25) * 25

    Object.entries(FeedbackTypes).forEach((value) => {
      if (value[0] === String(avg)) {
        setScoreType(value[1])
      }
    })
  }, [props.feedbacks])

  return (
    <div className="row display-feedback">
      <div className="col-md-12">
        <h3>
          GesamTbewertung <Help positions={['bottom']} ><div>
            <p> Die Gesamtnote wird aus den Teilbewertungen berechnet. Alle vorhandenden Bewertungskriterien gehen zu gleichen Teilen in die Gesamtbewertung ein.</p>
<p> Wird eine Kategorie mit “keine Bewertung” (-1) bewertet, wird der Text insgesamt mit “keine Bewertung” bewertet.</p>
<p> Für die Gesamtnote gilt folgendes Bewertungsschema: 100-88% sehr gut, 87-75% gut, 74-63% befriedigend, 62-50% ausreichend, 49-0% ungenügend.</p>
</div></Help>
        </h3>

        <button className="btn btn-light">
          {skipped ? 'keine Bewertung (-1)' : `${scoreType} (${score}%)`}
        </button>
      </div>
    </div>
  )
}

import React from 'react'
import Correction from 'models/Correction'
import { EditorContext } from 'context/editorContext'
import { EditorContextType } from '../../@types/editor'
import Save from 'assets/icons/Save'
import Trash from 'assets/icons/Trash'

interface CorrectionPopoverProps {
  correction: Correction
}

export default function CorrectionPopover (props: CorrectionPopoverProps): JSX.Element {
  const {
    acceptSuggestion,
    deleteCorrection
  } = React.useContext(EditorContext) as EditorContextType

  return (
    <div className="popover-content" style={{ minWidth: 65, textAlign: 'center' }}>
      {!props.correction.accepted && props.correction.suggestion &&
        <button type="button" className="btn btn-success no-border mr-1" onClick={() => acceptSuggestion(props.correction)}>
          <Save color={'#fff'} />
        </button>
      }
      <button type="button" className="btn btn-transparent" onClick={() => deleteCorrection(props.correction)}>
        <Trash />
      </button>
    </div>
  )
}

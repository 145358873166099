import Token from './Token'
import CorrectionType from './CorrectionType'
import uuidv4 from '../utils/uuidv4'

export default class Correction {
  id: number | string
  tokens: Token[] = []
  correction: string = ''
  type: CorrectionType
  comment?: string
  strikedOut: boolean = false
  accepted: boolean = false
  suggestion: boolean = false
  root: boolean = true
  suggestions: string[] = []
  activeSuggestion?: number
  suggestionIndex?: number
  suggestionType: string | null = null
  children: Correction[] = []

  constructor (type: CorrectionType, tokens: Token[], comment?: string) {
    this.id = uuidv4()
    this.type = type
    this.tokens = tokens
    this.comment = comment
  }
}

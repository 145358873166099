export default class FeedbackCriteria {
  id: number
  name: string
  description: string
  shortDescription: string

  constructor (id: number, name: string, shortDescription: string, description: string) {
    this.id = id
    this.name = name
    this.description = description
    this.shortDescription = shortDescription
  }
}

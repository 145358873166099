import React from 'react'

export default function Minus (): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_703)">
        <path d="M17.2863 12H6.71387" stroke="#47ABD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <circle cx="12" cy="12" r="11" stroke="#47ABD8" strokeWidth="2"/>
      <defs>
        <clipPath id="clip0_1549_703">
          <rect width="12" height="12" fill="white" transform="translate(6 6)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

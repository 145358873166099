import React from 'react'

export default function Accept (): JSX.Element {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1669_1253)">
        <path d="M7.27393 10.721L10.3759 13.823L15.6197 8.5792" stroke="#5F676B" strokeWidth="1.5"
          strokeMiterlimit="10" />
        <path
          d="M15.9029 4.12966H6.18353C5.0492 4.12966 4.12964 5.04921 4.12964 6.18354V15.9029C4.12964 17.0372 5.0492 17.9568 6.18353 17.9568H15.9029C17.0372 17.9568 17.9568 17.0372 17.9568 15.9029V6.18354C17.9568 5.04921 17.0372 4.12966 15.9029 4.12966Z"
          stroke="#5F676B" strokeWidth="1.5" strokeMiterlimit="10" />
        <path
          d="M3.0971 14.8703C1.96112 14.8703 1.04321 13.9492 1.04321 12.8165V3.0971C1.04321 1.96112 1.96112 1.04321 3.0971 1.04321H12.8165C13.9524 1.04321 14.8703 1.96437 14.8703 3.0971"
          stroke="#5F676B" strokeWidth="1.5" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_1669_1253">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

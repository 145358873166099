import React from 'react'
import PropTypes from 'prop-types'
interface SaveProps {
  color?: string
}

Save.propTypes = {
  color: PropTypes.string
}

const defaultProps = {
  color: '#4AABD9'
}

export default function Save (propsIn: SaveProps): JSX.Element {
  const props = { ...defaultProps, ...propsIn }

  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_645)">
        <path d="M4.71777 8.90195L8.16625 12.3106L18.1938 2.39233" stroke={props.color} strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1549_645">
          <rect width="19" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

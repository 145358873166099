import React, { useEffect, useState } from 'react'
import './InkaToolbar.scss'
import View from 'assets/icons/View'
import { EditorContext } from 'context/editorContext'
import { EditorContextType } from '../../@types/editor'
import Correction from 'models/Correction'
import CorrectionTypes from 'enums/CorrectionTypes'
import Cancel from 'assets/icons/Cancel'
import Token from 'models/Token'
import CorrectionType from 'models/CorrectionType'
import TokenService from 'services/TokenService'

interface InkaInfoBlockProps {
  name: string
  suggestion: string
  description: string
  maxCount?: number | null
}

interface ErrorTypeMap {
  [name: string]: CorrectionType
}

export default function InkaInfoBlock (props: InkaInfoBlockProps): JSX.Element {
  const {
    suggestionsEnabled,
    hiddenSuggestionIndexes,
    corrections,
    setSuggestionsEnabled,
    setCorrections,
    setTokens,
    setCorrection,
    deleteSuggestions,
    setHiddenCorrections
  } = React.useContext(EditorContext) as EditorContextType

  const maxCount = props.maxCount ?? 0

  const [cachedCount, setCachedCount] = useState<number | null>(null)

  useEffect(() => {
    if (props.suggestion.length > 0) {
      setCachedCount(getCount())
    }
  }, [props.suggestion])

  const getCount = (): number => {
    const [,,correctionIndex] = getCorrections()

    return correctionIndex
  }

  const toggleSuggestions = (): void => {
    if (suggestionsEnabled !== props.name) {
      setSuggestionsEnabled(props.name)

      const [tokens, cors] = getCorrections()
      let correctionIndex = cors.length
      if (props.name !== 'errors') {
        setHiddenCorrections(corrections.filter((cor) => !cor.suggestion))
        setCorrections(cors)
      } else {
        corrections
          .filter((cor) => !cor.suggestion)
          .forEach((correction) => {
            correction.id = correctionIndex
            correctionIndex++
            cors.push(correction)
          })
        setCorrections(cors)
      }
      setTokens(tokens)
    } else {
      setSuggestionsEnabled('')
      deleteSuggestions()
      setCorrection(undefined)
    }
  }

  const getCorrections = (): [Token[], Correction[], number] => {
    let tokenIndex = 0
    let correctionIndex = 0

    const toks: Token[] = []
    const cors: Correction[] = []

    const errorTypeMap: ErrorTypeMap = {
      omission: CorrectionTypes.SKIP,
      phrase: CorrectionTypes.EXPRESSION,
      grammar: CorrectionTypes.GRAMMAR,
      repetition: CorrectionTypes.REPEAT,
      punctuation: CorrectionTypes.PUNCTUATION,
      spelling: CorrectionTypes.SPELLING,
      syntax: CorrectionTypes.SYNTAX,
      note: CorrectionTypes.NOTE
    }

    new DOMParser()
      .parseFromString(props.suggestion, 'text/html').body.childNodes.forEach((node) => {
        if (node.textContent !== null) {
          if (node.nodeType === 3) { // text node
            new TokenService(node.textContent, tokenIndex).getTokens().forEach((token) => {
              toks.push(token)
              tokenIndex++
            })
          } else {
            const element = node as HTMLElement
            const correction = new Correction(CorrectionTypes.NOTE, [])

            const suggestions = element.getAttribute('data-error-correction')

            if (suggestions !== 'undefined' && suggestions !== null && suggestions.length > 0) {
              correction.suggestions = suggestions.split('|')
            }
            correction.suggestion = true
            if (correction.suggestions.length > 0) {
              correction.correction = correction.suggestions[0]
              correction.activeSuggestion = 0
            }

            const type = element.getAttribute('data-type')

            if (type !== null && type === props.name) {
              correction.suggestionType = type
              if (type === 'errors') {
                correction.suggestionIndex = correctionIndex
              }

              const errorType = element.getAttribute('data-error-type')

              if (errorType != null) {
                correction.type = errorTypeMap[errorType]
              }

              new TokenService(node.textContent, tokenIndex).getTokens().forEach((token) => {
                correction.tokens.push(token)
                toks.push(token)
                tokenIndex++
              })

              correctionIndex++

              if (hiddenSuggestionIndexes.find((i) => i === correction.suggestionIndex) !== undefined) {
                return
              }

              const duplicate = corrections.find((c) => c.tokens === correction.tokens)
              if (duplicate !== undefined) {
                duplicate.suggestion = true
              } else {
                cors.push(correction)
              }
            } else {
              new TokenService(node.textContent, tokenIndex).getTokens().forEach((token) => {
                toks.push(token)
                tokenIndex++
              })
            }
          }
        }
      })
    return [toks, cors, correctionIndex]
  }

  return (
    <div className="info-block">
      <div className="info-description">{props.description}</div>
      <div className="info-result" onClick={() => toggleSuggestions()}>
        <span>{cachedCount}{(maxCount > 0) ? ` / ${maxCount}` : ''}</span> {suggestionsEnabled === props.name
          ? <Cancel/>
          : <View/>}</div>
    </div>
  )
}

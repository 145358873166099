import React from 'react'

export default function Folder (): JSX.Element {
  return (
    <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_429_2311)">
        <path
          d="M3.16595 7.35443L32.7951 1.39033C33.0858 1.33136 33.3854 1.32955 33.6768 1.38508C33.9682 1.44061 34.2457 1.55238 34.4934 1.71396C34.741 1.87554 34.9541 2.08376 35.1204 2.32677C35.2866 2.56978 35.4028 2.84283 35.4623 3.13025L39.3542 24.3769L5.28879 31.2378L1.40598 9.9912C1.34632 9.70382 1.34453 9.40764 1.4007 9.11958C1.45687 8.83151 1.5699 8.55719 1.73334 8.31232C1.89679 8.06746 2.10743 7.85686 2.35324 7.6925C2.59905 7.52814 2.87522 7.41324 3.16595 7.35443V7.35443Z"
          fill="#5AC8F5" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
        <path
          d="M5.64258 32.2061H46.639V50.8518C46.639 51.8603 46.2337 52.8276 45.5124 53.5407C44.791 54.2538 43.8126 54.6545 42.7925 54.6545H9.48911C8.46895 54.6545 7.49057 54.2538 6.7692 53.5407C6.04784 52.8276 5.64258 51.8603 5.64258 50.8518V32.2061Z"
          fill="white" stroke="#A0C814" strokeWidth="3" strokeMiterlimit="10"/>
        <path
          d="M43.564 29.2285L8.80002 29.2285V14.3765C8.80002 13.7842 9.03802 13.2162 9.46165 12.7974C9.88528 12.3786 10.4598 12.1433 11.059 12.1433L41.2597 12.1433C41.8588 12.1433 42.4334 12.3786 42.857 12.7974C43.2806 13.2162 43.5186 13.7842 43.5186 14.3765V29.2285H43.564Z"
          fill="#5AC8F5" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_429_2311">
          <rect width="48" height="56" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'

export default function Cancel (): JSX.Element {
  return (
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1640_2168)">
        <path d="M4.55933 3.09061V0.600586H16.4314V18.1097H4.55933V15.7279" stroke="#82055F" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.498779 5.39355L8.51991 13.3066" stroke="#82055F" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M8.51991 5.39355L0.498779 13.3066" stroke="#82055F" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1640_2168">
          <rect width="17" height="18.7" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

import React from 'react'

export default function Punctuation (): JSX.Element {
  return (
    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.56415 16.5294C1.56415 16.5294 1.50445 16.5294 1.48057 16.5294C0.764152 16.4818 0.214898 15.8754 0.262659 15.1501L1.1343 1.33363C1.18206 0.620216 1.79102 0.0851541 2.51938 0.120825C3.23579 0.168386 3.78505 0.774788 3.73729 1.50009L2.86564 15.3166C2.81788 16.0062 2.24475 16.5294 1.56415 16.5294Z"
        fill="#95CFE5"/>
    </svg>
  )
}

import React from 'react'

export default function Mobile (): JSX.Element {
  return (
    <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_429_2331)">
        <path
          d="M12.6964 0.59082H3.30374C1.82 0.59082 0.617188 1.74262 0.617188 3.16345V19.8363C0.617188 21.2571 1.82 22.4089 3.30374 22.4089H12.6964C14.1801 22.4089 15.3829 21.2571 15.3829 19.8363V3.16345C15.3829 1.74262 14.1801 0.59082 12.6964 0.59082Z"
          stroke="#B7B7B7" strokeWidth="2" strokeMiterlimit="10"/>
        <path
          d="M7.99792 18.2229C8.80228 18.2229 9.45433 17.5985 9.45433 16.8282C9.45433 16.058 8.80228 15.4336 7.99792 15.4336C7.19356 15.4336 6.5415 16.058 6.5415 16.8282C6.5415 17.5985 7.19356 18.2229 7.99792 18.2229Z"
          stroke="#B7B7B7" strokeWidth="2" strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_429_2331">
          <rect width="16" height="23" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

import React, { useState, useEffect } from 'react'
import Editor from 'components/editor/Editor'
import Edit from 'assets/icons/Edit'
import Frame from 'assets/icons/Frame'
import Play from 'assets/icons/Play'
import Back from 'assets/icons/Back'
import Check from 'assets/icons/Check'
import CollapsibleSection from 'components/CollapsibleSection'
// import redemittelBox from 'assets/images/redemittel_box.png'
import Revision from 'models/Revision'
import Revisions from 'components/revision/Revisions'
import Wysiwyg from 'components/Wysiwyg'
import FeedbackEditor from '../components/feedback/FeedbackEditor'
import './Teacher.scss'
import Profile from 'components/Profile'
import InkaToolbar from 'components/inka/InkaToolbar'
import Help from 'components/Help'
import EditorProvider from 'context/editorContext'
import { ExampleContext, ExampleContextType } from 'context/exampleContext'
import { useNavigate } from 'react-router-dom'

function strip (html: string): string {
  const tmp: HTMLElement = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent !== null ? tmp.textContent : tmp.innerText
}

function Teacher (): JSX.Element {
  const [newAttempt, setNewAttempt] = useState(false)
  const [submission, setSubmission] = useState('')
  const [cleanSubmission, setCleanSubmission] = useState('')
  const [notify, setNotify] = useState(false)
  const [showRecommentdations, setShowRecommentdations] = useState(false)
  const {
    currentExample,
    currentExampleIndex = 0
  } = React.useContext(ExampleContext) as ExampleContextType
  const navigate = useNavigate()

  useEffect(() => {
    const submisson: string = currentExample?.submission !== undefined ? currentExample?.submission : ''
    setSubmission(submisson)
    setCleanSubmission(strip(submisson))
  }, [currentExample])

  const nextExample = (): void => {
    navigate(`/teacher/${currentExampleIndex + 2}`)
  }
  return (
    <EditorProvider content={(new Revision(1, cleanSubmission)).text}>
      <div className="container teacher">
        <div className="row">
          <div className="col-12">
            <Profile name="John Ming" task="" />
          </div>
        </div>
        <InkaToolbar task={currentExample?.chapter} example={currentExample} suggestions={submission} />
        <div className="row">
          <div className="col-md-12">

            <CollapsibleSection collapsible={true} icon={<Frame />} title="Aufgabe">
              <div className="row">
                <div className="col-md-6 task-box">
                  <h3>AUFGABENSTELLUNG</h3>
                  <p>
                    {currentExample?.task}
                  </p>

                  <div>
                    <a className="btn btn-outline-light" href="">
                      VIDEO ABSPIELEN <Play />
                    </a>
                  </div>
                  <div>
                    <a className="btn btn-outline-light" href="">
                      AUDIO ABSPIELEN <Play />
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <h3>
                    REDEMITTEL BOX
                  </h3>
                  <div className="idioms-box">
                    <h3>{currentExample?.idiomsHeader}</h3>
                    <div className="idioms-inner">
                      {/* <div className="idiom-header">
                      <p> Meine Freundin/Mein Freund heißt …</p>
                    </div> */}
                      <div className="idiom-content">
                        { currentExample?.idioms.map((idiom, index) => <p key={index}>{idiom}</p>)}
                      </div>
                    </div>
                  </div>
                  {/* <img src={redemittelBox} alt=""/> */}
                </div>
              </div>
            </CollapsibleSection>

            <CollapsibleSection collapsible={true} icon={<Frame />} title="Referenztext">
              <Revisions revisions={[new Revision(1, cleanSubmission), new Revision(2, submission)]} />
            </CollapsibleSection>

            <CollapsibleSection collapsible={false} icon={<Edit />} title="Korrektur">
              <Editor name="correction" showCorrectionBox={true} />
            </CollapsibleSection>

            <CollapsibleSection collapsible={false} icon={<Back />} title="Feedback als kommentar">
              <Wysiwyg content="Test" />
            </CollapsibleSection>

            <CollapsibleSection collapsible={false} icon={<Check />} title="Bewertung">
              <FeedbackEditor />
            </CollapsibleSection>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="submit-admission">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="newAttemptCheck" name="newAttemptCheck"
                    checked={newAttempt} onChange={() => setNewAttempt(!newAttempt)} />
                  <label className="custom-control-label" htmlFor="newAttemptCheck">Neuen Versuch anfordern</label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="notifyCheck" name="notifyCheck"
                    checked={notify} onChange={() => setNotify(!notify)} />
                  <label className="custom-control-label" htmlFor="notifyCheck">Teilnehmer benachrichtigen</label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="showRecommendationCheck"
                    name="showRecommendationCheck" checked={showRecommentdations}
                    onChange={() => setShowRecommentdations(!showRecommentdations)} />
                  <label className="custom-control-label" htmlFor="showRecommendationCheck">Lernempfehlungen
                    anzeigen
                    <Help positions={['bottom']} ><div>Folgende Empfehlungen werden angezeigt:
                      <ul>
                        <li>
                          Anzeige nicht verwendeter Redemittel
                        </li>
                        <li>
                          Link zum Grammatiktrainer
                        </li>
                        <li>
                          Link zum Vokabeltrainer
                        </li>
                      </ul>
                    </div>
                    </Help>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="submit-buttons">
                <a className="btn btn-outline-light">
                  Abbrechen
                </a>
                <button type="button" className="btn btn-success" onClick={() => nextExample()}>
                  SPEICHERN und ABSENDEN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditorProvider>
  )
}

export default Teacher

import React from 'react'

export default function Frame (): JSX.Element {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_38_2425)">
        <path d="M15.1544 11.9089V17.1823C15.1544 17.6942 14.9605 18.1851 14.6153 18.5473C14.2702 18.9095 13.8019 19.1133 13.3135 19.114H2.68387C2.19543 19.1133 1.72719 18.9095 1.38204 18.5473C1.03688 18.1851 0.843017 17.6942 0.843018 17.1823V2.81522C0.843018 2.56189 0.890628 2.31103 0.983139 2.07698C1.07565 1.84293 1.21124 1.63027 1.38218 1.45113C1.55312 1.272 1.75606 1.1299 1.9794 1.03296C2.20274 0.936009 2.44213 0.886108 2.68387 0.886108H13.3135C13.8017 0.886108 14.2699 1.08935 14.6152 1.45113C14.9604 1.81291 15.1544 2.30359 15.1544 2.81522V11.9089Z" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.59497 5.85059H11.5864" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.59497 9.87598H11.5864" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.59497 13.7089H8.92896" stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_38_2425">
          <rect width="16" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React, { useState } from 'react'
import { PopoverPosition } from 'react-tiny-popover'
import HelpIcon from 'assets/icons/HelpIcon'
import PopoverBordered from './popover/PopoverBordered'

interface HelpProps {
  children: JSX.Element
  positions: PopoverPosition[]
  maxWidth?: number
}

export default function Help (props: HelpProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <PopoverBordered popoverContent={props.children} maxWidth={props.maxWidth} positions={props.positions} opened={isPopoverOpen} info={true} close={true} stateChanger={setIsPopoverOpen}>
      <button className="btn btn-link"
              onMouseEnter={() => setIsPopoverOpen(true)}
              onMouseLeave={() => setIsPopoverOpen(false)}
      >
        <HelpIcon/>
      </button>
    </PopoverBordered>
  )
}

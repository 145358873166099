import Token from './Token'
import Correction from './Correction'
import CorrectionTypes from '../enums/CorrectionTypes'
import TokenService from '../services/TokenService'

export default class Revision {
  id: number | string
  tokens: Token[] = []
  corrections: Correction[] = []
  text: string = ''

  constructor (id: number | string, text: string) {
    this.id = id
    this.text = text
    let tokenIndex = 0
    new DOMParser()
      .parseFromString(text, 'text/html').body.childNodes.forEach((node) => {
        if (node.textContent !== null) {
          if (node.nodeType === 3) { // text node
            new TokenService(node.textContent, tokenIndex).getTokens().forEach((token) => {
              this.tokens.push(token)
              tokenIndex++
            })
          } else {
            const element = node as HTMLElement
            const correction = new Correction(CorrectionTypes.NOTE, [])

            const comment = element.getAttribute('data-comment')
            if (comment != null) {
              correction.comment = comment
            }

            const previous = element.getAttribute('data-previous')

            if (previous != null) {
              correction.correction = previous
            }

            const type = element.getAttribute('data-type')

            if (type != null) {
              // @TODO idk in which format type gonna be
            }

            new TokenService(node.textContent, tokenIndex).getTokens().forEach((token) => {
              correction.tokens.push(token)
              this.tokens.push(token)
              tokenIndex++
            })
            this.corrections.push(correction)
          }
        }
      })
  }
}

import React from 'react'
import './FeedbackItem.scss'
import Help from '../Help'
import Feedback from 'models/Feedback'
import ChevronDown from 'assets/icons/ChevronDown'

interface FeedbackItemProps {
  feedback: Feedback
  border?: boolean
}

export default function FeedbackItem (props: FeedbackItemProps): JSX.Element {
  const getStatusClass = (feedback: Feedback): string => {
    if (feedback.score === undefined || feedback.score < 25) {
      return 'status-bad'
    }

    if (feedback.score > 75) {
      return 'status-good'
    }

    return 'status-medium'
  }

  const getStatusDescription = (feedback: Feedback): string => {
    if (feedback.score === undefined || feedback.score < 25) {
      return 'Beinahe!'
    }

    if (feedback.score > 85) {
      return 'Super!'
    } else if (feedback.score > 75) {
      return 'Gut gemacht!'
    }

    return 'Passt!'
  }

  return (
    <div className="col-md-3 feedback-item">
      <div className={props.border === true ? 'border-wrapper' : ''}>
        <h3>{props.feedback.criteria.name} <Help positions={['bottom']}>
          <div>{props.feedback.criteria.description}</div>
        </Help></h3>
        <p>{props.feedback.criteria.shortDescription}</p>
        <div className="down">
          <ChevronDown/>
        </div>
        <div className="score">
          <span className={`status ${getStatusClass(props.feedback)}`}></span> {getStatusDescription(props.feedback)}
        </div>
      </div>
      <div className="help">
        Was bedeutet das?
      </div>
    </div>
  )
}

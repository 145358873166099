import React from 'react'
export default function Attention (): JSX.Element {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <circle cx="9" cy="9" r="8" stroke="#40627E" strokeWidth="2"/>
      </g>
      <line opacity="0.5" x1="9" y1="5" x2="9" y2="10" stroke="#40627E" strokeWidth="2"/>
      <line opacity="0.5" x1="9" y1="11" x2="9" y2="13" stroke="#40627E" strokeWidth="2"/>
    </svg>
  )
}

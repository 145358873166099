import React from 'react'
export default function View (): JSX.Element {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1401_4198)">
        <path d="M6.84433 6.52245C7.98309 6.52254 9.10078 6.84518 10.0798 7.4564C11.0587 8.06762 11.8628 8.94483 12.4073 9.9957C11.8627 11.0464 11.0586 11.9234 10.0796 12.5344C9.10063 13.1455 7.983 13.468 6.84433 13.468C5.70566 13.468 4.58805 13.1455 3.60908 12.5344C2.63011 11.9234 1.82598 11.0464 1.28132 9.9957C1.82586 8.94483 2.62993 8.06762 3.6089 7.4564C4.58788 6.84518 5.70557 6.52254 6.84433 6.52245M6.84433 5.31226C5.39439 5.31265 3.97576 5.75544 2.76076 6.58684C1.54575 7.41824 0.586669 8.60248 0 9.9957C0.587313 11.3881 1.54667 12.5713 2.76162 13.4019C3.97657 14.2325 5.39487 14.6746 6.84433 14.6746C8.29379 14.6746 9.71209 14.2325 10.927 13.4019C12.142 12.5713 13.1014 11.3881 13.6887 9.9957C13.102 8.60248 12.1429 7.41824 10.9279 6.58684C9.71292 5.75544 8.29427 5.31265 6.84433 5.31226Z" fill="#47ABD8"/>
        <path d="M6.84431 9.07298C7.0193 9.07302 7.19032 9.12781 7.33557 9.23036C7.48081 9.33291 7.59372 9.47859 7.65987 9.64881C7.72602 9.81904 7.74242 10.0061 7.707 10.1862C7.67158 10.3662 7.58594 10.5311 7.46098 10.6599C7.33602 10.7886 7.17742 10.8753 7.00538 10.9089C6.83334 10.9426 6.65565 10.9216 6.49498 10.8488C6.33431 10.7759 6.19793 10.6544 6.10323 10.4998C6.00852 10.3452 5.95978 10.1645 5.96321 9.98063C5.96773 9.73825 6.06257 9.50741 6.22732 9.33769C6.39207 9.16798 6.61359 9.07294 6.84431 9.07298M6.84431 7.86279C6.44151 7.86281 6.04781 7.98855 5.71314 8.22407C5.37848 8.45959 5.11793 8.79426 4.96459 9.18562C4.81125 9.57698 4.77202 10.0074 4.85187 10.4222C4.93172 10.837 5.12704 11.2176 5.41308 11.5156C5.69911 11.8136 6.06296 12.0156 6.45842 12.0959C6.85389 12.1763 7.26316 12.1314 7.6343 11.9669C8.00544 11.8025 8.32172 11.5259 8.543 11.1722C8.76429 10.8186 8.88059 10.4038 8.87717 9.98063C8.87261 9.41728 8.65642 8.87864 8.27568 8.48198C7.89494 8.08532 7.38047 7.86277 6.84431 7.86279V7.86279Z" fill="#47ABD8"/>
        <path d="M4.47754 3.77823V1.25195H16.4242V19.0418H4.47754V16.6456" stroke="#47ABD8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1401_4198">
          <rect width="17" height="19" fill="white" transform="translate(0 0.646973)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'

export default function Back (): JSX.Element {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_699)">
        <path
          d="M2.1925 4.56274H8.31918C9.9204 4.56432 11.4555 5.18758 12.5872 6.29554C13.7188 7.4035 14.3545 8.90556 14.3545 10.4717V10.4717C14.3545 11.2469 14.1984 12.0144 13.8951 12.7306C13.5918 13.4468 13.1472 14.0975 12.5868 14.6457C12.0264 15.1938 11.361 15.6286 10.6288 15.9253C9.89655 16.2219 9.11175 16.3746 8.31918 16.3746H2.1925"
          stroke="#5F676B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.66498 8.50601L0.639404 4.56277L4.66498 0.625488" stroke="#5F676B" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1549_699">
          <rect width="15" height="17" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'

export default function Mosaic (): JSX.Element {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_377_11218)">
        <path d="M6.09428 0.77832H0.753418V6.29709H6.09428V0.77832Z" stroke="#808080" strokeWidth="1.5"
              strokeMiterlimit="10"/>
        <path d="M6.09428 10.7031H0.753418V16.2219H6.09428V10.7031Z" stroke="#808080" strokeWidth="1.5"
              strokeMiterlimit="10"/>
        <path d="M15.2544 0.77832H9.91357V6.29709H15.2544V0.77832Z" stroke="#808080" strokeWidth="1.5"
              strokeMiterlimit="10"/>
        <path d="M15.2544 10.7031H9.91357V16.2219H15.2544V10.7031Z" stroke="#808080" strokeWidth="1.5"
              strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_377_11218">
          <rect width="16" height="17" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

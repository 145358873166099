import React from 'react'
import './Tokens.scss'
import Token from 'models/Token'
import CorrectionSpan from './CorrectionSpan'
import Correction from 'models/Correction'
import SuggestionSpan from './SuggestionSpan'

interface RevisionsProps {
  tokens: Token[]
  selectedTokens?: Token[]
  corrections: Correction[]
  stateChanger?: (correction: Correction) => void
  editor?: boolean
}

export default function Tokens (props: RevisionsProps): JSX.Element {
  const renderedIds: Array<number | string> = []

  const selectContents = function (el: any): void {
    const range = document.createRange()
    range.selectNodeContents(el)
    const sel = window.getSelection()
    if (sel !== null) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }

  return (
    <div className="tokens">
      {props.tokens.map((token, key) => {
        const correction = props.corrections.find(c => c.tokens.find(t => t.id === token.id))
        if (correction !== undefined) {
          if (renderedIds.includes(correction.id)) {
            return null
          }
          renderedIds.push(correction.id)

          if (correction.suggestion) {
            return (
              <SuggestionSpan key={token.id} correction={correction} stateChanger={props.stateChanger}/>
            )
          }

          return (
            <CorrectionSpan key={token.id} correction={correction} editor={props.editor}/>
          )
        }

        const classes = []

        if (props.selectedTokens?.find(t => t.id === token.id) !== undefined) {
          classes.push('selected')
        }

        return (
          <span onClick={(e) => selectContents(e.target)} className={classes.join(' ')} key={token.id} data-id={token.id} dangerouslySetInnerHTML={{ __html: token.value }}></span>
        )
      })}
    </div>
  )
}
